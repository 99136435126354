define('data-entry/list-view.controller',['angular'], function (angular) {
    'use strict';
    var DataListViewCtrl = function ($scope, userService, user, dataEntryService, $location, $routeParams, $mdDialog) {
        $scope.user = user.data.User;
        $scope.statusFilter = 'todos';
        $scope.formTypeFilter = 'all';
        $scope.quarterFilter = 'all';
        $scope.formId = '';
        $scope.first = true;
        $scope.isChecked = false;
        $scope.errorForms = [];
        $scope.viewPDF = function (l) {
            l.downloading = true;
            if ($scope.curPDF != undefined) {
                $scope.curPDF.downloading = false;
            }
            $scope.curPDF = l;
            dataEntryService.getABBYYPDF($scope.user.sessionID, l.id).then(function (response) {
                var file = new Blob([response.data], {type: "application/pdf"});
                l.downloading = $scope.curPDF.downloading = false;
                if ($scope.curPDF.id == l.id) {
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        $scope.msFile = file;
                        $scope.fileURL = 'tmp.pdf';
                        window.navigator.msSaveOrOpenBlob(file, 'polst.pdf');
                        $scope.showDownload = true;
                    } else {
                        $scope.showDownload = true;
                        $scope.fileURL = URL.createObjectURL(file);
                        var w = window.open($scope.fileURL, '_blank');
                        //"File " + l.id);
                        console.log('hello');
                        console.log(w);
                        w.document.title = 'Form ID ' + l.id;
                        console.log(w.document.title);
                    }
                }
            });

        };
        $scope.downloadMetadata = function (l) {
        };
        $scope.orgFilter = '0';
        $scope.formTypeFilter = 'all';
        $scope.seen_orgs = {};
        $scope.orgs = [];
        $scope.getForms = function () {
            $scope.myPromise = dataEntryService.getABBYYForms($scope.user.sessionID, $scope.statusFilter, $scope.orgFilter, $scope.formTypeFilter,
                $scope.quarterFilter, $scope.formId).then(function (r) {
                // return from API call
                $scope.formList = r.data;
                for (var i = 0; i < $scope.formList.length; i++) {
                    var org = $scope.formList[i].org;
                    if ($scope.seen_orgs[org.id] === undefined) {
                        $scope.seen_orgs[org.id] = true;
                        $scope.orgs.push(org);
                    }
                    var tmp = moment.utc($scope.formList[i].created, 'YYYY-MM-DD HH:mm:ss');
                    $scope.formList[i].createdDate = tmp.tz('America/Los_Angeles').format('hh:mm a MM/DD/YYYY z');
                    tmp = moment.utc($scope.formList[i].date_submitted, 'YYYY-MM-DD HH:mm:ss');
                    $scope.formList[i].submittedDate = tmp.tz('America/Los_Angeles').format('MM/DD/YYYY');
                    if ($scope.formList[i].metadata !== undefined) {
                        var content = $scope.formList[i].metadata;
                        var blob = new Blob([content], {type: 'text/plain'});
                        $scope.formList[i].url = (window.URL || window.webkitURL).createObjectURL(blob);
                    }
                }
                $scope.orgs.sort(function(a,b){
                    return a.name.localeCompare(b.name);
                });
                if ($scope.first) {
                    $scope.first = false;
                    $scope.formList.sort(function (a, b) {
                        if (a.date_submitted === undefined && b.date_submitted === undefined) {
                            return 0;
                        } else if (a.date_submitted === undefined) {
                            return 1;
                        } else if (b.date_submitted === undefined) {
                            return -1;
                        }
                        var a2 = moment.utc(a.date_submitted, 'YYYY-MM-DD HH:mm:ss');
                        var b2 = moment.utc(b.date_submitted, 'YYYY-MM-DD HH:mm:ss');
                        if (a2.isBefore(b2)) {
                            return 1;
                        } else if (a2.isAfter(b2)) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                }
                $scope.onPaginate($scope.formTable.page, $scope.formTable.limit);
            });
        };
        $scope.formTable = {
            order: 'submitted',
            limit: 10,
            page: 1
        };
        $scope.orgFilter = $location.search().currentOrg;
        $scope.formTypeFilter = $location.search().formType;
        if($scope.formTypeFilter == undefined){
            $scope.formTypeFilter = 'all';
        }
        $scope.getForms();
        $scope.onPaginate = function (page, limit) {
            console.log(page + ' ' + limit);
            $scope.formTable.page = page;
            $scope.formTable.limit = limit;
            $scope.reorderForms();
        };
        $scope.reorderForms = function () {
            var start = (($scope.formTable.page - 1) * $scope.formTable.limit);
            var end = start + $scope.formTable.limit;
            $scope.formView = [];
            console.log(start + ' ' + end + ' ' + $scope.formList.length);
            for (var i = start; i < end && i < $scope.formList.length; i++) {
                $scope.formView.push($scope.formList[i]);
            }
        };
        $scope.formView = [];
        $scope.onReorder = function (order) {
            console.log(order);
            if (order === 'id') {
                $scope.formList.sort(function (a, b) {
                    return a.id > b.id ? -1 : a.id < b.id ? 1 : 0;
                });
            } else if (order === '-id') {
                $scope.formList.sort(function (a, b) {
                    return a.id > b.id ? 1 : a.id < b.id ? -1 : 0;
                });
            } else if (order === '-orgid') {
                $scope.formList.sort(function (a, b) {
                    return a.org.name.toLowerCase() > b.org.name.toLowerCase() ? -1 : b.org.name.toLowerCase() > a.org.name.toLowerCase() ? 1 : 0;
                });
            } else if (order === 'orgid') {
                $scope.formList.sort(function (a, b) {
                    return a.org.name.toLowerCase() > b.org.name.toLowerCase() ? 1 : b.org.name.toLowerCase() > a.org.name.toLowerCase() ? -1 : 0;
                });
            } else if (order === '-submitted') {
                $scope.formList.sort(function (a, b) {
                    if (a.date_submitted === undefined && b.date_submitted === undefined) {
                        return 0;
                    } else if (a.date_submitted === undefined) {
                        return -1;
                    } else if (b.date_submitted === undefined) {
                        return 1;
                    }
                    var a1 = moment.utc(a.date_submitted, 'YYYY-MM-DD HH:mm:ss');
                    var b1 = moment.utc(b.date_submitted, 'YYYY-MM-DD HH:mm:ss');
                    if (a1.isBefore(b1)) {
                        return -1;
                    } else if (a1.isAfter(b1)) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            } else if (order === 'submitted') {
                $scope.formList.sort(function (a, b) {
                    if (a.date_submitted === undefined && b.date_submitted === undefined) {
                        return 0;
                    } else if (a.date_submitted === undefined) {
                        return 1;
                    } else if (b.date_submitted === undefined) {
                        return -1;
                    }
                    var a2 = moment.utc(a.date_submitted, 'YYYY-MM-DD HH:mm:ss');
                    var b2 = moment.utc(b.date_submitted, 'YYYY-MM-DD HH:mm:ss');
                    if (a2.isBefore(b2)) {
                        return 1;
                    } else if (a2.isAfter(b2)) {
                        return -1;
                    } else {
                        return 0;
                    }

                });
            } else if (order === '-created') {
                $scope.formList.sort(function (a, b) {
                    if (a.created === undefined && b.created === undefined) {
                        return 0;
                    } else if (a.created === undefined) {
                        return -1;
                    } else if (b.created === undefined) {
                        return 1;
                    }
                    var a1 = moment.utc(a.created, 'YYYY-MM-DD HH:mm:ss');
                    var b1 = moment.utc(b.created, 'YYYY-MM-DD HH:mm:ss');
                    if (a1.isBefore(b1)) {
                        return -1;
                    } else if (a1.isAfter(b1)) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            } else if (order === 'created') {
                $scope.formList.sort(function (a, b) {
                    if (a.created === undefined && b.created === undefined) {
                        return 0;
                    } else if (a.created === undefined) {
                        return 1;
                    } else if (b.created === undefined) {
                        return -1;
                    }
                    var a2 = moment.utc(a.created, 'YYYY-MM-DD HH:mm:ss');
                    var b2 = moment.utc(b.created, 'YYYY-MM-DD HH:mm:ss');
                    if (a2.isBefore(b2)) {
                        return 1;
                    } else if (a2.isAfter(b2)) {
                        return -1;
                    } else {
                        return 0;
                    }

                });
            } else if (order === 'formStatus') {
                $scope.formList.sort(function (a, b) {
                    return a.formStatus > b.formStatus ? 1 : a.formStatus < b.formStatus ? -1 : 0;
                });
            } else if (order === '-formStatus') {
                $scope.formList.sort(function (a, b) {
                    return a.formStatus > b.formStatus ? -1 : a.formStatus < b.formStatus ? 1 : 0;
                });

            }
            $scope.reorderForms();
        };
        $scope.getForms();
        $scope.filterByStatus = function () {
            $scope.getForms();
        };
        $scope.filterByOrg = function () {
            $location.search().currentOrg = $scope.orgFilter;
            $location.search($location.search());
            $scope.getForms();
        };
        $scope.filterByLabel = function () {
            $location.search().formType = $scope.formTypeFilter;
            $location.search($location.search());
            $scope.getForms();
        };
        $scope.byFormId = function () {
            $scope.getForms();
        };
        $scope.filterByQuarter = function () {
            $scope.getForms();
        };
        $scope.processAD = function (l) {
            $location.path('/data-entry/verify-ad').search('id', l.id);
        };
        $scope.processForm = function (l) {
            $location.path('/data-entry/verify').search('afid', l.id);
        };
        $scope.uploadPOLST = function () {
            $location.path('/data-entry/upload');
        };
        $scope.uploadCsv = function (l) {
            l.selected = false;
            $location.path('/data-entry/upload-csv').search({id: l.id});
        };
        $scope.addToUploadError = function ($event, l) {
            $event.stopPropagation();
            if (l.selected === undefined || !l.selected) {
                const selected = {id: l.id};
                $scope.errorForms.push(selected);
            } else {
                const tmp = [];
                for (var i = 0; i < $scope.errorForms.length; i++) {
                    if ($scope.errorForms[i].id !== l.id) {
                        tmp.push($scope.errorForms[i]);
                    }
                }
                $scope.errorForms = tmp;
            }
        };
        $scope.rowClicked = function (l) {
            l.selected = l.selected === undefined || !l.selected;
            if (l.selected) {
                const selected = {id: l.id};
                $scope.errorForms.push(selected);
            } else {
                const tmp = [];
                for (var i = 0; i < $scope.errorForms.length; i++) {
                    if ($scope.errorForms[i].id !== l.id) {
                        tmp.push($scope.errorForms[i]);
                    }
                }
                $scope.errorForms = tmp;
            }
        };
        $scope.markAsUploadError = function () {
            $scope.uploadErrorPromise = dataEntryService.markUploadError($scope.user.sessionID, null, $scope.errorForms).then(function () {
                $scope.getForms();
                $scope.errorForms = [];
            });
        };
        $scope.claimBack = function (l) {
            var reviewed = false;
            if (l.is_pt_upload) {
                dataEntryService.checkReviewed($scope.user.sessionID, l.patientID).then(function (r) {
                    if (true || r.data.reviewed) {
                        l.formStatus = 'assigned';
                        l.assignedUserid = $scope.user.id;
                        dataEntryService.claimBack(l.formStatus, l.assignedUserid, $scope.user.sessionID, l.id).then(function (r) {
                            l.assignedUser = r.data;
                        }, function (e) {
                            console.log(e);
                        });
                    } else {
                        $mdDialog.show({
                            controller: 'ReviewPatientAlertCtrl',
                            templateUrl: 'data-entry/review-patient-alert.html',
                            locals: {},
                            parent: document.body,
                            clickOutsideToClose: true,
                            fullscreen: true
                        }).then(function (response) {
                            if (response !== undefined && response.review) {
                                $mdDialog.show({
                                    controller: 'MatchDetailsCtrl',
                                    templateUrl: 'data-entry/match-details.html',
                                    locals: {user: $scope.user, patient: r.data.match},
                                    parent: document.body,
                                    clickOutsideToClose: true,
                                    fullscreen: true
                                }).then(function (response) {
                                    if (response !== undefined && response.reviewed) {
                                        l.is_reviewed = true;
                                    } else if (response !== undefined && response.confirmed) {
                                        l.is_reviewed = true;
                                        l.assigned_patient_id = response.patient.id;
                                    }
                                });
                            }
                        });
                    }
                })
            } else {
                l.formStatus = 'assigned';
                l.assignedUserid = $scope.user.id;
                dataEntryService.claimBack(l.formStatus, l.assignedUserid, $scope.user.sessionID, l.id).then(function (r) {
                    l.assignedUser = r.data;
                }, function (e) {
                    console.log(e);
                });
            }
        };

    };
    DataListViewCtrl.$inject = ['$scope', 'userService', 'user', 'dataEntryService', '$location', '$mdDialog'];
    return DataListViewCtrl;
});

