define('data-entry/publish-or-archive-ad.controller',['angular'], function (angular) {
    'use strict';
    var PublishOrArchiveAdCtrl = function ($scope, $location, $routeParams, $filter, $localStorage, $mdToast, $document, userService, dataEntryService, $window, $mdDialog, $sce, helper, adform) {

        var searchForCurrent = function () {
            $scope.otherAD = dataEntryService.searchForCurrentAD(adform.patientID).then(function (response) {
                var tmp = moment.utc(response.data);
                $scope.currentDate = tmp.tz('America/Los_Angeles').format('MM/DD/YYYY');
            });
        };
        searchForCurrent();
        $scope.publish = 1;
        $scope.viewADPDF = function () {
            if ($scope.fileUrl !== undefined) {
                window.open($scope.fileAD_URL, "_blank");
            } else {
                userService.downloadADForm(adform.patientID).then(function (response) {
                    var file = new Blob([response.data], {type: "application/pdf"});
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        $scope.msFileAD = file;
                        $scope.fileAD_URL = 'tmp.pdf';
                        // window.navigator.msSaveOrOpenBlob(file, 'polst.pdf');
                        $scope.showDownload = true;
                        window.navigator.msSaveOrOpenBlob($scope.msFileAD, 'polst.pdf');
                    } else {
                        $scope.showDownload = true;
                        $scope.fileAD_URL = URL.createObjectURL(file);
                        window.open($scope.fileAD_URL, "_blank");
                    }
                });
            }
        };
        $scope.confirm = function () {
            $mdDialog.hide({publish: $scope.publish});
        };
    };
    PublishOrArchiveAdCtrl.$inject = ['$scope', '$location', '$routeParams', '$filter', '$localStorage', '$mdToast', '$document', 'userService', 'dataEntryService', '$window', '$mdDialog', '$sce', 'helper', 'adform'];
    return PublishOrArchiveAdCtrl;
});

