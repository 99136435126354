define('dashboard/verify-physician.controller',['angular'], function (angular) {
    'use strict';
    var VerifyPhysicianCtrl = function ($scope, userService, $mdDialog, $document) {
        $scope.search = {};
        $scope.formSubmitted = false;
        $scope.dataReady = false;
        $scope.npiSearchSubmitted = false;
        $scope.md = undefined;
        $scope.npi = "";
        $scope.searchNPPES = function (valid) {
            $scope.formSubmitted = true;
            if (valid) {
                $scope.busyTxt = 'Saving Information';
                $scope.myPromise = userService.findPhysician($scope.search).then(function (response) {
                    $scope.showResults = true;
                    $scope.docs = response.data.results;
                });
            }
        };
        $scope.changeDoc = function (d) {
            for (var i = 0; i < $scope.docs.lenght; i++) {
                var d2 = $scope.docs[i].number;
                if (d2.number !== d.number) {
                    d2.isSelected = false;
                }
            }
            $scope.showNoneSelected = false;
        };
        $scope.setIdentity = function () {
            var d;
            for (var i = 0; i < $scope.docs.length; i++) {
                if ($scope.docs[i].isSelected) {
                    d = $scope.docs[i];
                }
            }
            if (d === undefined) {
                $scope.showNoneSelected = true;
            } else {
                userService.setNPI(d.number, d.addresses[0].telephone_number,  d.basic.first_name, d.basic.last_name).then(function () {
                    $mdDialog.hide(true);
                });
            }
        };
        $scope.credentialsChanged = function () {
            $scope.loginSubmitted = false;
        };
        $scope.credentials = {};
        $scope.loginUser = function (credentials) {
            $scope.isProcessing = true;
            $scope.loginSubmitted = true;
            $scope.busyTxt = 'Saving Information';
            $scope.myPromise = userService.loginUser(credentials, $scope).then(function () {
                $scope.isProcessing = false;
                if ($scope.validLogin) {
                    $location.path("/dashboard");
                } else {

                }
            });
        };
        $scope.setTouched = function () {
        };
        userService.checkLogin().then(function () {
            if (userService.getUser() === undefined) {
                $scope.isLoggedIn = false;
            } else {
                $scope.isLoggedIn = true;
            }
        }, function () {
            $scope.isLoggedIn = false;
        });
        $scope.busyTxt = 'Saving Information';
        $scope.searchNPPES = function (valid) {
            $scope.formSubmitted = true;
            $scope.busyTxt = 'Checking NPI';
            $scope.myPromise = userService.findPhysician($scope.search).then(function (response) {
                $scope.showResults = true;
                $scope.docs = response.data.results;
            });
        };
        $scope.lookupNPI = function () {
            $scope.npiSearchSubmitted = true;
            $scope.busyTxt = 'Saving Information';
            $scope.myPromise = userService.validateNPI({npi: $scope.npi}).then(function (response) {
                $scope.showResults = true;
                $scope.docs = response.data.results;
                if ($scope.docs === undefined) {
                    $scope.docs = [];
                }
                if ($scope.docs.length > 0) {
                    $scope.d = $scope.docs[0];
                    $scope.d.isSelected = true;
                }
            });
        };
        $scope.signupPhysician = function () {
            var params = {
                npi: $scope.npi,
                fname: $scope.search.fname,
                lname: $scope.search.lname,
                email: $scope.credentials.email,
                street: $scope.search.street,
                unit: $scope.search.unit,
                city: $scope.search.city,
                state: $scope.search.state,
                zip: $scope.search.zip,
                phone: $scope.search.phone
            };
            if($scope.credentials.email !== undefined) {
                userService.checkEmail({email: $scope.credentials.email}, $scope).then(function (res) {
                    if (res.status != 400) {
                        $scope.busyTxt = 'Saving Information';
                        $scope.myPromise = userService.physicianSignup(params).then(function (res) {
                            $scope.registered = true;
                        });
                    }
                });
            } else {
                $scope.npiSearch.email.$touched = true;
            }
        };
        $scope.credentialsChanged = function(){
            $scope.emailExists = false;
        };
        $scope.checkEmailDupe = function(){

        };
    };
    VerifyPhysicianCtrl.$inject = ['$scope', 'userService', '$mdDialog', '$document'];
    return VerifyPhysicianCtrl;
});

