/**
 * Configure routes of user module.
 */
define('user/user.routes',['angular', './user.controllers', 'common'], function (angular, controllers) {
    'use strict';

    var mod = angular.module('user.routes', ['user.services', 'com.cd.common']);
    mod.config(['$routeProvider', 'userResolve', '$locationProvider', function ($routeProvider, userResolve, $locationProvider) {
        $locationProvider.hashPrefix('');
        $routeProvider.when("/register", {
            templateUrl: 'user/register.html',
            controller: controllers.RegCtrl
        }).when('/covid-19', {
            templateUrl: 'user/covid.html',
            controller: controllers.CovidSignupCtrl
        }).when('/analytics-signup', {
            templateUrl: 'user/analytics-signup.html',
            controller: controllers.AnalyticsSignupCtrl
        }).when('/smartError', {
            templateUrl: 'user/smart-error.html',
            controller: controllers.SmartErrorCtrl
        }).when('/smartAuth', {
            templateUrl: 'user/smart-auth.html',
            controller: controllers.SmartAuthCtrl
        }).when('/smartLogin', {
            templateUrl: 'user/smart-login.html',
            controller: controllers.SmartLoginCtrl
        }).when('/mdsignup', {
            templateUrl: 'user/md-signup.html',
            controller: controllers.MDSignupCtrl
        }).when("/account", {
            templateUrl: 'users/account.html',
            controller: controllers.UserCtrl,
            reloadOnSearch: false,
            resolve: userResolve
        }).when('/user-settings', {
            templateUrl: 'user/user-settings.html',
            controller: controllers.UserSettingsCtrl,
            resolve: userResolve
        }).when("/caresettings", {
            templateUrl: 'user/caresettings.html',
            controller: controllers.CSCtrl,
            reloadOnSearch: false
        }).when("/smartTimeout", {
            templateUrl: 'user/smart-session-invalid.html',
            controller: controllers.SmartSessionInvalidCtrl
        }).when("/resetpassword", {
            templateUrl: 'user/forgotpassword.html',
            controller: controllers.ResetPWCtrl
        }).when("/unsub", {
            templateUrl: 'user/unsub.html',
            controller: controllers.UnsubCtrl
        }).when("/setpassword", {
            templateUrl: 'user/setpassword.html',
            controller: controllers.SetPWCtrl
        }).when("/select-preferred-org", {
            templateUrl: 'user/select-preferred-org.html',
            controller: controllers.SelectPreferredOrgCtrl,
            resolve: userResolve
        });
        // .when('/users', {templateUrl:'/assets/templates/user/users.html',
        // controller:controllers.UserCtrl})
        // .when('/users/:id',
        // {templateUrl:'/assets/templates/user/editUser.html',
        // controller:controllers.UserCtrl});
    }]);
    return mod;
});

