define('user/md-signup.controller',['angular'], function (angular) {
    'use strict';
    var MDSignupCtrl = function ($scope, helper, userService, $location, $document) {
        $scope.isProd = window.isProd;
        $scope.credentialsChanged = function () {
            $scope.loginSubmitted = false;
        };
        $scope.credentials = {};
        $scope.loginUser = function (credentials) {
            $scope.isProcessing = true;
            $scope.loginSubmitted = true;
            $scope.busyTxt = 'Creating Account';
            $scope.myPromise = userService.loginUser(credentials, $scope).then(function () {
                $scope.isProcessing = false;
                if ($scope.validLogin) {
                    $location.path("/dashboard");
                } else {

                }
            });
        };
        $scope.setTouched = function () {
            setTimeout(function () {
                var elem = angular.element($document[0].querySelector('input[type=password]:-webkit-autofill'));

                if (elem.length) {
                    elem.parent().addClass('md-input-has-value');
                }
            }, 150);
        };
        userService.checkLogin().then(function () {
            if (userService.getUser() === undefined) {
                $scope.isLoggedIn = false;
            } else {
                $scope.isLoggedIn = true;
            }
        }, function () {
            $scope.isLoggedIn = false;
        });
        $scope.searchNPPES = function (valid) {
            $scope.formSubmitted = true;
            $scope.busyTxt = 'Looking up NPI';
            $scope.myPromise = userService.findPhysician($scope.search).then(function (response) {
                $scope.showResults = true;
                $scope.docs = response.data.results;
            });
        };
        $scope.npiSearchSubmitted = false;
        $scope.md = undefined;
        $scope.npi = "";



        $scope.busyTxt = 'Looking up NPI';
        $scope.lookupNPI = function () {
            $scope.npiSearchSubmitted = true;
            $scope.busyTxt = 'Looking up NPI';
            $scope.myPromise = userService.validateNPI({npi: $scope.npi}).then(function (response) {
                $scope.showResults = true;
                if(response.data.results !== undefined && response.data.results.length > 0 && response.data.results[0].enumeration_type === 'NPI-1') {
                    $scope.docs = response.data.results;
                    if ($scope.docs === undefined) {
                        $scope.docs = [];
                    }
                    if ($scope.docs.length > 0) {
                        $scope.d = $scope.docs[0];
                        $scope.d.isSelected = true;
                    }
                } else {
                    $scope.docs = [];
                }
            });
        };
        $scope.signupPhysician = function () {
            var params = {
                npi: $scope.npi,
                fname: $scope.search.fname,
                lname: $scope.search.lname,
                email: $scope.credentials.email,
                street: $scope.search.street,
                unit: $scope.search.unit,
                city: $scope.search.city,
                state: $scope.search.state,
                zip: $scope.search.zip,
                phone: $scope.search.phone
            };
            if($scope.credentials.email !== undefined) {
                userService.checkEmail({email: $scope.credentials.email}, $scope).then(function (res) {
                    if (res.status != 400) {
                        $scope.myPromise = userService.physicianSignup(params).then(function (res) {
                            $scope.registered = true;
                        });
                    }
                });
            } else {
                $scope.npiSearch.email.$touched = true;
            }
        };
        $scope.credentialsChanged = function(){
            $scope.emailExists = false;
        };
        $scope.checkEmailDupe = function(){

        };
        $scope.setIdentity = function (d) {
            if (d.isSelected) {
                $scope.md = d;
                $scope.search = {};
                $scope.search.email = '';
                $scope.search.fname = d.basic.first_name;
                $scope.search.lname = d.basic.last_name;
                $scope.search.street = d.addresses[0].address_1;
                $scope.search.unit = d.addresses[0].address_2;
                $scope.search.city = d.addresses[0].city;
                $scope.search.state = d.addresses[0].state;
                $scope.search.zip = d.addresses[0].postal_code;
                $scope.search.phone = d.addresses[0].telephone_number;
            }
        };
    };
    MDSignupCtrl.$inject = ['$scope', 'helper', 'userService', '$location', '$document'];
    return MDSignupCtrl;
});

