define('user/user.controllers',['angular',
    './reg.controller',
    './resetpw.controller',
    './setpw.controller',
    './md-signup.controller',
    './smart-login.controller',
    './unsub.controller',
    './select-org.controller',
    './pw-update.controller',
    './smart-auth.controller',
    './smart-error.controller',
    './user-settings.controller',
    './set-sms.controller',
    './select-preferred-org',
    './smart-session-invalid.controller',
    './analytics-signup.controller',
    './covid-signup.controller',
], function (angular, RegCtrl, ResetPWCtrl, SetPWCtrl, MDSingupCtrl, SmartLoginCtrl, UnsubCtrl, SelectOrgCtrl, PwUpdateCtrl, SmartAuthCtrl, SmartErrorCtrl, UserSettingsCtrl, SetSMSCtrl, SelectPreferredOrgCtrl, SmartSessionInvalidCtrl, AnalyticsSignupCtrl, CovidSignupCtrl) {
    'use strict';

    return {
        AnalyticsSignupCtrl: AnalyticsSignupCtrl,
        SmartSessionInvalidCtrl: SmartSessionInvalidCtrl,
        SetSMSCtrl: SetSMSCtrl,
        UserSettingsCtrl: UserSettingsCtrl,
        SmartErrorCtrl: SmartErrorCtrl,
        SmartAuthCtrl: SmartAuthCtrl,
        PwUpdateCtrl: PwUpdateCtrl,
        RegCtrl: RegCtrl,
        SelectOrgCtrl: SelectOrgCtrl,
        ResetPWCtrl: ResetPWCtrl,
        SetPWCtrl: SetPWCtrl,
        MDSignupCtrl:MDSingupCtrl,
        SmartLoginCtrl: SmartLoginCtrl,
        UnsubCtrl: UnsubCtrl,
        SelectPreferredOrgCtrl: SelectPreferredOrgCtrl,
        CovidSignupCtrl: CovidSignupCtrl,
    };

});

