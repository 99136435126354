/**

 * User service, exposes user model to the rest of the app.
 */
define('user/user.services',['angular', 'common'], function (angular) {
    'use strict';
    var isSmart = false;
    var mod = angular.module('user.services', ['com.cd.common']);
    mod.factory('userService', ['$http', '$q', 'playRoutes', '$localStorage', '$mdDialog', '$location', 'Idle', '$rootScope', function ($http, $q, playRoutes, $localStorage, $mdDialog, $location, Idle, $rootScope) {
        var user, UUID = $localStorage.sid;
        if (UUID) {
            playRoutes.com.cd.controllers.SessionManager.authUser(UUID).get().then(function (data) {
                user = data.data.User;
                UUID = user.sessionID;
            }, function () {
                UUID = undefined;
                delete $localStorage.sid;
                return $q.reject("Token invalid");
            });
        }

        var timeoutid;


        return {
            generateUUID: function () {
                return playRoutes.com.cd.controllers.SessionManager.generateUUID().get().then(function (response) {
                    return response;
                });
            },
            smartSendEmailForLogging: function (content) {
                return playRoutes.com.cd.controllers.Smart.smartSendEmailForLogging().post({content: content}).then(function (response) {
                    return response;
                });
            },
            signupAnalyticsUser: function (credentials) {
                return playRoutes.com.cd.controllers.Users.singupAnalyticsUser().post({
                    email: credentials.email,
                    code: credentials.code,
                    firstname: credentials.firstname,
                    lastname: credentials.lastname
                }).then(function (response) {
                    return response;
                });
            },
            downloadAdvanceDirectiveAsImageById: function (id) {
                return playRoutes.com.cd.controllers.AdvanceDirectives.downloadAdvanceDirectiveAsImageById().post({
                    id: id,
                    sid: UUID,
                    pref_org_id: $localStorage.pref_org_id
                }).then(function (response) {
                    return response.data;
                });
            },
            downloadFormAsImageById: function (id) {
                return playRoutes.com.cd.controllers.Forms.downloadFormAsImageById().post({
                    id: id,
                    sid: UUID,
                    pref_org_id: $localStorage.pref_org_id
                }).then(function (response) {
                    return response.data;
                });
            },
            getSignedForms: function () {
                return playRoutes.com.cd.controllers.Forms.getSignedForms().post({
                    sid: $localStorage.sid
                });
            },
            getPendingForms: function () {
                return playRoutes.com.cd.controllers.Forms.getPendingForms().post({
                    sid: $localStorage.sid,
                    pref_org_id: $localStorage.pref_org_id
                });
            },
            getNumPending: function (sid) {
                return playRoutes.com.cd.controllers.Forms.getNumPending().post({
                    sid: sid,
                    pref_org_id: $localStorage.pref_org_id
                });
            },
            downloadFormById: function (id) {
                return $http.post(playRoutes.com.cd.controllers.Forms.downloadFormById().url, {
                    sid: UUID,
                    id: id, pref_org_id: $localStorage.pref_org_id
                }, {responseType: 'arraybuffer'}).then(function (response) {
                    return response;
                });
                //return playRoutes.com.cd.controllers.Forms.downloadForm().post({
                //    sid: UUID,
                //    pid: id
                //}).then(function (response) {
                //    return response;
                //});
            },
            downloadForm: function (id) {
                return $http.post(playRoutes.com.cd.controllers.Forms.downloadForm().url, {
                    sid: UUID,
                    pid: id, pref_org_id: $localStorage.pref_org_id
                }, {responseType: 'arraybuffer'}).then(function (response) {
                    return response;
                });
                //return playRoutes.com.cd.controllers.Forms.downloadForm().post({
                //    sid: UUID,
                //    pid: id
                //}).then(function (response) {
                //    return response;
                //});
            },

            checkIfACP: function (id) {
                return playRoutes.com.cd.controllers.Forms.checkIfACP().post({
                    sid: UUID,
                    pid: id
                }).then(function (response) {
                    return response;
                });

            },
            checkIfAD: function (id) {
                return playRoutes.com.cd.controllers.AdvanceDirectives.checkIfAD().post({
                    sid: UUID,
                    pid: id
                }).then(function (response) {
                    return response;
                });

            },
            downloadADById: function (id) {
                return $http.post(playRoutes.com.cd.controllers.AdvanceDirectives.downloadADById().url, {
                    sid: UUID,
                    id: id, pref_org_id: $localStorage.pref_org_id
                }, {responseType: 'arraybuffer'}).then(function (response) {
                    return response;
                });
            },
            downloadACPForm: function (id, form_type) {
                return $http.post(playRoutes.com.cd.controllers.Forms.downloadACPForm().url, {
                    sid: UUID,
                    form_type: form_type,
                    pid: id, pref_org_id: $localStorage.pref_org_id
                }, {responseType: 'arraybuffer'}).then(function (response) {
                    return response;
                });
            },
            downloadADForm: function (id) {
                return $http.post(playRoutes.com.cd.controllers.AdvanceDirectives.downloadADForm().url, {
                    sid: UUID,
                    pid: id, pref_org_id: $localStorage.pref_org_id
                }, {responseType: 'arraybuffer'}).then(function (response) {
                    return response;
                });
            },

            removeUserSMS: function (userid) {
                return playRoutes.com.cd.controllers.Admin.removeUserSMS().post({
                    sid: UUID,
                    userid: userid
                }).then(function (response) {
                    return response;
                });
            },
            addUserSMS: function (userid, phone) {
                return playRoutes.com.cd.controllers.Admin.addUserSMS().post({
                    sid: UUID,
                    userid: userid,
                    phone: phone
                }).then(function (response) {

                });
            },
            sendSMSVerifyCode: function (phone) {
                return playRoutes.com.cd.controllers.SessionManager.sendSMSVerifyCode().post({
                    sid: UUID,
                    phone: phone
                }).then(function (response) {
                    return response;
                });
            },
            sendSMSVerifyCodeNotLoggedIn: function (phone) {
                return playRoutes.com.cd.controllers.SessionManager.sendSMSVerifyCodeNotLoggedIn().post({
                    phone: phone
                }).then(function (response) {
                    return response;
                });

            },
            textLink: function (phone) {
                return playRoutes.com.cd.controllers.Covid19.textLink().post({
                    phone: phone
                }).then(function (response) {
                    return response;
                });
            },
            covidSignup: function (userInfo, scope) {
                return playRoutes.com.cd.controllers.Covid19.signupUser().post({
                    userInfo: userInfo
                }).then(function (response) {
                    if(response.data.User !== undefined) {
                        $localStorage.pref_org_id = undefined;
                        delete $localStorage.pref_org_id;
                        scope.user = response.data.User;
                        UUID = scope.user.sessionID;
                        $localStorage.sid = UUID;
                        user = scope.user;
                        $rootScope.$emit('rootScope:login', response.data.User);
                    }
                    return response;
                });
            },
            setSMSNumber: function (phone, code) {
                return playRoutes.com.cd.controllers.SessionManager.setSMSNumber().post({
                    sid: UUID,
                    phone: phone,
                    code: code
                }).then(function (response) {
                    return response;
                });
            },
            verifySMSLoginCode: function (phone, code, scope) {
                return playRoutes.com.cd.controllers.SessionManager.verifySMSLoginCode().post({
                    phone: phone,
                    code: code
                }).then(function (response) {
                    if (response.data.User !== undefined) {
                        $localStorage.pref_org_id = undefined;
                        delete $localStorage.pref_org_id;
                        if (window.emulateLLUMC) {
                            response.data.User.smartSession = {isLLUMC: true};
                        }
                        user = response.data.User;
                        scope.user = user;
                        scope.validLogin = true;
                        $localStorage.sid = user.sessionID;
                        UUID = $localStorage.sid;
                    }
                    $rootScope.$emit('rootScope:login', response.data.User);
                }, function (response) {
                    if (response.status === 400 || response.status === 415) {
                        if (response.data === 'locked out') {
                            scope.validLogin = false;
                            scope.lockedOut = true;
                        } else {
                            scope.validLogin = false;
                            scope.lockedOut = false;
                        }
                    }
                });
            },
            sendSMSLoginCode: function (phone) {
                return playRoutes.com.cd.controllers.SessionManager.sendSMSLoginCode().post({phone: phone}).then(function (response) {
                    return response;
                });
            },
            getOrgAdmins: function () {
                return playRoutes.com.cd.controllers.SessionManager.getOrgAdmins().post({sid: UUID}).then(function (response) {
                    return response;
                });
            },
            signForm: function (params) {
                params.sid = UUID;
                return playRoutes.com.cd.controllers.Forms.signForm().post(params).then(function (response) {
                    return response;
                });
            },
            getSnfByLink: function (params) {
                return playRoutes.com.cd.controllers.Providers.getSnfByLink().post(params).then(function (resposne) {
                    return resposne.data;
                });
            },
            agreementLink: function (params) {
                return playRoutes.com.cd.controllers.Providers.agreementLink().post(params).then(function (response) {
                    return response;
                });
            },
            validateNPI: function (params) {
                return playRoutes.com.cd.controllers.Providers.validateNPI().post(params).then(function (response) {
                    return response;
                });
            },
            physicianSignup: function (params) {
                return playRoutes.com.cd.controllers.Providers.signupPhysician().post(params).then(function (response) {
                    return response;
                });
            },
            matchPatient: function (params) {
                params.sid = UUID;
                params.pref_org_id = $localStorage.pref_org_id;
                return playRoutes.com.cd.controllers.Patients.matchPatient().post(params).then(function (response) {
                    return response;
                });
            },
            searchPhysicians: function (text) {
                return playRoutes.com.cd.controllers.Providers.getPhysicains().post({text: text}).then(function (response) {
                    return response.data;
                });
            },
            searchHospice: function (text) {
                return playRoutes.com.cd.controllers.Patients.searchHospice().post({text: text}).then(function (response) {
                    return response.data;
                });
            },
            unlockUser: function (userid) {
                return playRoutes.com.cd.controllers.Admin.unlockUser().post({
                    userid: userid,
                    pref_org_id: $localStorage.pref_org_id,
                    sid: UUID
                });
            },
            swapActive: function (email, active) {
                return playRoutes.com.cd.controllers.SessionManager.swapActive().post({
                    email: email,
                    sid: UUID,
                    active: active
                });
            },
            setNPI: function (npi, phone, fname, lname) {
                return playRoutes.com.cd.controllers.SessionManager.setNPI().post({
                    npi: npi,
                    phone: phone,
                    fname: fname,
                    lname: lname,
                    sid: UUID
                }).then(function () {
                    user.isPhysicianVerified = 1;
                    user.npi = npi;
                    user.firstname = fname;
                    user.lastname = lname;
                    user.phone = phone;
                });
            },
            updatePatient: function (patient) {
                var params = {patient: patient, sid: UUID};
                return playRoutes.com.cd.controllers.Patients.updatePatient().post(params);
            },
            findPhysician: function (search) {
                return playRoutes.com.cd.controllers.Providers.getNPPESAPI().post(search);
            },
            editUser: function (credentials) {
                credentials.sid = UUID;
                return playRoutes.com.cd.controllers.SessionManager.editUser().post(credentials);
            },
            getPolstForms: function (sid) {
                return playRoutes.com.cd.controllers.Admin.getPolstForms().post({sid: sid}).then(function (response) {
                    return response;
                });
            },
            addUser: function (credentials) {
                credentials.sid = UUID;
                return playRoutes.com.cd.controllers.SessionManager.addUser().post(credentials);
            },
            getOrgs: function () {
                return playRoutes.com.cd.controllers.Data.getOrgs().post({});
            },
            getUsers: function () {
                return playRoutes.com.cd.controllers.SessionManager.getUsers().post({
                    sid: $localStorage.sid,
                    pref_org_id: $localStorage.pref_org_id
                });
            },
            searchForms: function (query) {
                return playRoutes.com.cd.controllers.Forms.searchForms().post({
                    q: query,
                    sid: $localStorage.sid,
                    pref_org_id: $localStorage.pref_org_id
                });
            },
            getFormsToSign: function () {
                return playRoutes.com.cd.controllers.Forms.getFormsToSign().post({sid: $localStorage.sid}).then(function (response) {
                    return response;
                });
            },
            getForms: function () {
                return playRoutes.com.cd.controllers.Forms.getForms().post({
                    sid: $localStorage.sid,
                    pref_org_id: $localStorage.pref_org_id
                });
            },
            changeAssignedPhysician: function (formID, physID) {
                return playRoutes.com.cd.controllers.Forms.changeAssignedPhysician().post({
                    sid: $localStorage.sid,
                    pref_org_id: $localStorage.pref_org_id,
                    formID: formID,
                    physID: physID
                });
            },
            // putADToSFTP: function (fid) {
            //     return playRoutes.com.cd.controllers.Smart.putADToLLUMCSFTP().post({
            //         sid: UUID,
            //         fid: fid
            //     }).then(function (response) {
            //     });
            // },
            importDocsFromEHR: function (sid) {
                return playRoutes.com.cd.controllers.Smart.importDocsFromEHR().post({
                    sid: sid
                });
            },
            exportADToEHR: function (fid) {
                return playRoutes.com.cd.controllers.Smart.exportADToEHR().post({
                    sid: UUID,
                    fid: fid
                }).then(function (response) {
                });
            },
            putToSFTP: function (fid) {
                return playRoutes.com.cd.controllers.Smart.putToLLUMCSFTP().post({
                    sid: UUID,
                    fid: fid
                }).then(function (response) {
                });
            },
            exportToEHR: function (fid) {
                return playRoutes.com.cd.controllers.Smart.exportToEHR().post({
                    sid: UUID,
                    fid: fid
                }).then(function (response) {
                });
            },
            exportToAdDir: function (fid) {
                return playRoutes.com.cd.controllers.Smart.exportToAdDir().post({
                    sid: UUID,
                    fid: fid
                }).then(function (response) {
                });
            },
            numToSign: function () {
                return playRoutes.com.cd.controllers.Forms.getNumToSign().post({sid: UUID}).then(function (response) {
                    return response;
                });
            },
            getForm: function (formid) {
                return playRoutes.com.cd.controllers.Forms.getForm().post({
                    formid: formid,
                    sid: $localStorage.sid,
                    pref_org_id: $localStorage.pref_org_id
                });
            },
            checkSMSUnique: function (phone) {
                return playRoutes.com.cd.controllers.Admin.checkSMSUnique().post({
                    phone: phone,
                    sid: UUID
                }).then(function (response) {
                    return response;
                });
            },
            checkEmail: function (credentials, scope) {
                return playRoutes.com.cd.controllers.SessionManager.checkEmail().post(credentials).then(function (response) {
                    return response;
                }, function (response) {
                    if (response.status === 400) {
                        scope.emailExists = true;
                        if (scope.attemptedEmails !== undefined) {
                            scope.attemptedEmails[scope.attemptedEmails.length + 1] = {
                                email: credentials.email
                            };

                        }
                        scope.checkEmailDupe();
                    }
                    return response;
                });
            },
            uploadPolst: function (picFile, json) {
                return $http.post(window.apiUrl + "/api/uploadPolst", {
                    model: {sid: $localStorage.sid, form: json},
                    file: picFile
                }, {
                    transformRequest: function (data) {
                        var formData = new FormData();
                        formData.append("model", angular.toJson(data.model));
                        formData.append("photo", picFile);
                        return formData;
                    },
                    headers: {'Content-Type': undefined}
                }).then(function (response) {
                    return response;
                });
            },
            createPolst: function (json) {
                return playRoutes.com.cd.controllers.Forms.createPolst().post({
                    form: json,
                    sid: $localStorage.sid,
                    pref_org_id: $localStorage.pref_org_id
                });
            },
            regUser: function (credentials, scope) {
                return playRoutes.com.cd.controllers.SessionManager.registerUser().post(credentials).then(function (response) {
                    user = response.data.User;
                    $localStorage.sid = user.sessionID;
                    UUID = user.sessionID;
                    scope.regSuccess = true;
                }, function (response) {
                    if (response.status === 400) {
                        scope.emailExists = true;
                        scope.attemptedEmails[scope.attemptedEmails.length + 1] = {
                            email: credentials.email
                        };
                    }
                });
            },
            verifyUnsubLink: function (credentials, scope) {
                return playRoutes.com.cd.controllers.Emailer.verifyUnsubLink(credentials).post(credentials).then(function (response) {
                    return response.data;
                }, function () {
                    scope.error = true;
                });
            },
            unsub: function (credentials, scope) {
                return playRoutes.com.cd.controllers.Emailer.unsub(credentials).post(credentials).then(function (response) {
                    return response.data;
                }, function () {
                    scope.error = true;
                });
            },
            verifyPassswordLink: function (credentials, scope) {
                return playRoutes.com.cd.controllers.SessionManager.verifyPassswordLink(credentials).post(credentials).then(function (response) {
                    return response.data;
                }, function () {
                    scope.error = true;
                });
            },
            setPassword: function (credentials, scope) {
                return playRoutes.com.cd.controllers.SessionManager.resetPassword().put(credentials).then(function (response) {
                    $localStorage.pref_org_id = undefined;
                    delete $localStorage.pref_org_id;
                    scope.user = response.data.User;
                    UUID = scope.user.sessionID;
                    $localStorage.sid = UUID;
                    user = scope.user;
                    $rootScope.$emit('rootScope:login', response.data.User);
                }, function (res) {
                    scope.error = true;
                    if (res.data === 'password previously used') {
                        scope.pwUsed = true;
                    }
                });
            },
            resetPassword: function (credentials, scope) {
                return playRoutes.com.cd.controllers.SessionManager.forgotPassword().post(credentials).then(function () {
                    scope.emailSent = true;
                }, function () {
                    scope.emailNotFound = true;
                });
            },
            loginUser: function (credentials, scope) {
                return playRoutes.com.cd.controllers.SessionManager.login().post(credentials).then(function (response) {
                    if (response.data.User !== undefined) {
                        $localStorage.pref_org_id = undefined;
                        delete $localStorage.pref_org_id;
                        if (window.emulateLLUMC) {
                            response.data.User.smartSession = {isLLUMC: true};
                        }
                        user = response.data.User;
                        scope.user = user;
                        scope.validLogin = true;
                        $localStorage.sid = user.sessionID;
                        UUID = $localStorage.sid;
                    } else if (response.data.redirect === true) {
                        scope.redirect = true;
                        scope.redirectEmail = response.data.email;
                        scope.redirectKey = response.data.key;
                    }
                    $rootScope.$emit('rootScope:login', response.data.User);
                }, function (response) {
                    if (response.status === 400 || response.status === 415) {
                        if (response.data.message === 'locked out') {
                            scope.validLogin = false;
                            scope.lockedOut = true;
                        } else {
                            scope.num_tries = response.data.count;
                            scope.validLogin = false;
                            scope.lockedOut = false;
                        }
                    }
                });
            },
            setLogin: function (sid) {
                console.log('calling setLogin!');
                $localStorage.sid = sid;
                UUID = sid;
                this.checkLogin().then(function (data) {
                    console.log('checkLogin return: ', data);
                    $rootScope.$emit('rootScope:login', data.data.User);
                });
            },
            contactUs: function (credentials) {
                return playRoutes.com.cd.controllers.Emailer.contactUs().post(credentials).then(function () {
                });
            },
            checkLoginWithSid: function (sid) {
                return playRoutes.com.cd.controllers.SessionManager.authUser(sid).get().then(function (data) {
                    if (window.emulateLLUMC) {
                        data.data.User.smartSession = {isLLUMC: true};
                    }
                    user = data.data.User;
                    UUID = user.sessionID;
                    $rootScope.$emit('rootScope:login', data.data.User);
                    return data;
                }, function () {
                    user = undefined;
                    $rootScope.$emit('rootScope:logout', {});
                });

            },
            getOrg: function (sid, orgid) {
                return playRoutes.com.cd.controllers.SessionManager.getOrg().post({
                    sid: sid,
                    orgid: orgid
                }).then(function (r) {
                    return r;
                });
            },
            checkLogin: function () {
                var u = UUID;
                if (u === undefined) {
                    u = null;
                }
                return playRoutes.com.cd.controllers.SessionManager.authUser(u).get().then(function (data) {
                    if (window.emulateLLUMC) {
                        data.data.User.smartSession = {isLLUMC: true};
                    }
                    user = data.data.User;
                    UUID = user.sessionID;
                    $rootScope.$emit('rootScope:login', data.data.User);
                    return data;
                }, function () {
                    user = undefined;
                    $rootScope.$emit('rootScope:logout', {});
                });

            },
            logout: function () {
                return playRoutes.com.cd.controllers.SessionManager.logout().post({
                    UUID: UUID
                }).then(function () {
                    $localStorage.pref_org_id = undefined;
                    delete $localStorage.pref_org_id;
                    if (timeoutid !== undefined) {
                        clearTimeout(timeoutid);
                    }
                    $localStorage.sid = undefined;
                    delete $localStorage.sid;
                    UUID = undefined;
                    user = undefined;
                    $rootScope.$emit('rootScope:logout', {});
                    $location.search({});
                    console.log('isSmart = ', isSmart);
                    if (isSmart) {
                        $location.path('/smartTimeout');
                    } else {
                        $location.path('/');
                    }
                }, function (e) {
                });
            },
            reset: function () {
                $localStorage.sid = undefined;
                delete $localStorage.sid;
                UUID = undefined;
                user = undefined;

            },
            getIsSmart: function () {
                return isSmart;
            },
            setIsSmart: function (param) {
                isSmart = param;
            },
            getUser: function () {
                return user;
            }
        };
    }]);
    mod.constant('physResolve', {
        user: ['$q', 'userService', '$localStorage', function ($q, userService, $localStorage) {
            var deferred = $q.defer();
            userService.checkLogin($localStorage.sid).then(function (user) {
                if (user.data.User.isPhyiscian) {
                    deferred.resolve(user);
                } else {
                    deferred.reject();
                }
            });
            return deferred.promise;
        }]
    });
    mod.constant('analyticsResolve', {
        user: ['$q', 'userService', '$localStorage', function ($q, userService, $localStorage) {
            var deferred = $q.defer();
            userService.checkLogin($localStorage.sid).then(function (user) {
                if (user.data.User.admin || user.data.User.is_analytics) {
                    deferred.resolve(user);
                } else {
                    deferred.reject();
                }
            });
            return deferred.promise;
        }]
    });
    mod.constant('adminResolve', {
        user: ['$q', 'userService', '$localStorage', function ($q, userService, $localStorage) {
            var deferred = $q.defer();
            userService.checkLogin($localStorage.sid).then(function (user) {
                if (user.data.User.admin) {
                    deferred.resolve(user);
                } else {
                    deferred.reject();
                }
            });
            return deferred.promise;
        }]
    });
    /**
     * Add this object to a route definition to only allow resolving the route
     * if the user is logged in. This also adds the contents of the objects as a
     * dependency of the controller.
     */
    mod.constant('userResolveNoLoginOk', {
        user: ['$q', 'playRoutes', 'userService', '$localStorage', '$location', function ($q, playRoutes, userService, $localStorage, $location) {
            var deferred = $q.defer();
            playRoutes.com.cd.controllers.SessionManager.authUser($localStorage.sid).get().then(function (data) {
                deferred.resolve(data);
            }, function () {
                deferred.resolve(undefined);
            });
            return deferred.promise;
        }]
    });
    mod.constant('acpBillingResolve', {
        user: ['$q', 'userService', '$localStorage', 'playRoutes', function ($q, userService, $localStorage, playRoutes) {
            var deferred = $q.defer();
            playRoutes.com.cd.controllers.SessionManager.authUser($localStorage.sid).get().then(function (data) {
                if (data.data.User.acp_billing_active) {
                    deferred.resolve(data);
                } else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });
            return deferred.promise;
        }]
    });
    mod.constant('userResolve', {
        user: ['$q', 'playRoutes', 'userService', '$localStorage', '$location', function ($q, playRoutes, userService, $localStorage, $location) {
            var deferred = $q.defer();
            playRoutes.com.cd.controllers.SessionManager.authUser($localStorage.sid).get().then(function (data) {
                deferred.resolve(data);
            }, function () {
                deferred.reject();
            });
            return deferred.promise;
        }]
    });
    mod.constant('superUserResolve', {
        user: ['$q', 'userService', '$localStorage', 'playRoutes', function ($q, userService, $localStorage, playRoutes) {
            var deferred = $q.defer();
            playRoutes.com.cd.controllers.SessionManager.authUser($localStorage.sid).get().then(function (data) {
                if (data.data.User.is_super_user) {
                    deferred.resolve(data);
                } else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });
            return deferred.promise;
        }]
    });
    mod.constant('dataResolve', {
        user: ['$q', 'userService', '$localStorage', 'playRoutes', function ($q, userService, $localStorage, playRoutes) {
            var deferred = $q.defer();
            playRoutes.com.cd.controllers.SessionManager.authUser($localStorage.sid).get().then(function (data) {
                if (data.data.User.is_data_entry) {
                    deferred.resolve(data);
                } else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });
            return deferred.promise;
        }]
    });
    /**
     * If the current route does not resolve, go back to the start page.
     */
    var handleRouteError = function ($rootScope, $location) {
        $rootScope.$on('$routeChangeError', function (/* e, next, current */) {
            console.log('isSmart = ', isSmart);
            if (isSmart) {
                $location.path('/smartTimeout');
            } else {
                $location.path('/');
            }
        });
    };
    handleRouteError.$inject = ['$rootScope', '$location'];
    mod.run(handleRouteError);
    return mod;
});

