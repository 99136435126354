define('data-entry/ad-upload.controller',['angular'], function (angular) {
    'use strict';
    var ADUploadCtrl = function ($scope, userService, user, dataEntryService, $routeParams, $filter, helper, $mdDialog, $mdToast) {
        $scope.user = user.data.User;
        $scope.invalidDocDate = true;
        $scope.form = {};
        var updateLabels = function () {
            helper.updateADLabels($scope, $filter, true);
        };
        updateLabels();
        var reset = function () {
            $scope.adform = {phone: ''};
            $scope.patientSelected = false;
            $scope.patientMatched = false;
            $scope.ptSearchDone = false;
            $scope.searching = false;
            $scope.polst = {
                secD2: 2,
                gender: '',
                sigRelationship: 'Self',
                addOrders: $filter('translate')('additional_orders') + ":\n\n",
                foodAddOrders: $filter('translate')('additional_orders') + ":\n\n",
                notCompByPhys: !$scope.user.phyiscian
            };
            $scope.fid = undefined;
            $scope.formSubmitted = false;
            $scope.isEmpty = false;
            $scope.savedSig = false;
            $scope.savedSig2 = false;
            $scope.searchErr = false;
            $scope.numResults = false;
            $scope.oneResult = false;
            $scope.search = {};
            $scope.hideCreate = false;
            $scope.searchForm = undefined;

            $scope.publish = 1;

            if ($scope.form.sform !== undefined) {
                $scope.form.sform.$setPristine();
                $scope.form.sform.$setUntouched();
            }
            if ($scope.form.pform !== undefined) {
                $scope.form.pform.$setPristine();
                $scope.form.pform.$setUntouched();
            }
        };
        var reinit = function () {
            reset();
            $scope.numToSign = 0;
            userService.numToSign().then(function (response) {
                $scope.numToSign = response.data.num;
            }, function (e) {

            });
            $scope.p = Number($routeParams.p);
            if ($scope.p === undefined || isNaN($scope.p)) {
                $scope.p = 0;
            }
            $scope.tabIndex = $scope.p;

        };
        $scope.$on('$routeChangeSuccess', function () {
            reinit();
        });
        $scope.$on('$locationChangeSuccess', function () {
            reinit();
            if (!$scope.loadedSmartContext) {
                $scope.loadedSmartContext = true;
                if ($scope.user.smartSession !== undefined) {
                    if ($scope.user.smartSession.gender !== undefined) {
                        $scope.search.gender = $scope.user.smartSession.gender;
                    }
                    if ($scope.user.smartSession.lname !== undefined) {
                        $scope.search.lname = $scope.user.smartSession.lname;
                    }
                    if ($scope.user.smartSession.fname !== undefined) {
                        $scope.search.fname = $scope.user.smartSession.fname;
                    }
                    if ($scope.user.smartSession.dob !== undefined) {
                        $scope.search.dob = $scope.user.smartSession.dob;
                    }
                }
            }
        });
        // PO
        $scope.states = helper.getStates();
        $scope.adform = {};
        $scope.genders = [{
            id: 1,
            name: "MALE"
        }, {
            id: 2,
            name: "FEMALE"
        }, {
            id: 3,
            name: 'UNDIFFERENTIATED'
        }];
        $scope.processForm = function (l) {
            console.log(l);
        };

        $scope.claimForm = function (l) {
            console.log(l);
        };

        $scope.viewADPDF = function (id) {
            if ($scope.fileUrl !== undefined) {
                window.open($scope.fileAD_URL, "_blank");
            } else {
                userService.downloadADById(id).then(function (response) {
                    var file = new Blob([response.data], {type: "application/pdf"});
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        $scope.msFileAD = file;
                        $scope.fileAD_URL = 'tmp.pdf';
                        // window.navigator.msSaveOrOpenBlob(file, 'polst.pdf');
                        $scope.showDownload = true;
                        window.navigator.msSaveOrOpenBlob($scope.msFileAD, 'AdvancedDirective.pdf');
                    } else {
                        $scope.showDownload = true;
                        $scope.fileAD_URL = URL.createObjectURL(file);
                        window.open($scope.fileAD_URL, "_blank");
                    }
                });
            }
        };
        $scope.viewExisting = function () {
            userService.downloadForm($scope.adform.patientID).then(function (response) {
                var file = new Blob([response.data], {type: "application/pdf"});
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    $scope.msFile = file;
                    $scope.fileURL = 'tmp.pdf';
                    window.navigator.msSaveOrOpenBlob(file, 'polst.pdf');
                    $scope.showDownload = true;
                } else {
                    console.log("not in .net version");
                    $scope.showDownload = true;
                    $scope.fileURL = URL.createObjectURL(file);
                    window.open($scope.fileURL, "_blank");
                }
            });

        };


        var searchForCurrent = function () {
            $scope.otherAD = dataEntryService.searchForCurrentAD($scope.adform.patientID).then(function (response) {
                var tmp = moment.utc(response.data);
                $scope.currentDate = tmp.tz('America/Los_Angeles').format('MM/DD/YYYY');

                // var tmp = moment.utc($scope.afform.polst.created);
                // $scope.currentDate = tmp.tz('America/Los_Angeles').format('hh:mm a MM/DD/YYYY z');
                // var newDate = moment($scope.afform.created);
                // $scope.newDate = $scope.afform.created;
                // if (newDate.isBefore(tmp)) {
                //     $scope.archiveRec = true;
                // } else {
                //     $scope.archiveRec = false;
                // }

            });
        };

        $scope.searching = false;
        $scope.searchPts = function () {
            if (!$scope.searching) {
                $scope.searching = true;
                userService.matchPatient({
                    lname: $scope.adform.lname,
                    fname: $scope.adform.fname,
                    mname: $scope.adform.mname,
                    street: $scope.adform.street,
                    city: $scope.adform.city,
                    state: $scope.adform.state,
                    zip: $scope.adform.zip,
                    dob: $scope.adform.dob,
                    ssn: $scope.adform.ssn,
                    gender: $scope.adform.gender,
                    ins: $scope.adform.ins,
                    phone: $scope.adform.phone === undefined ? undefined: $scope.adform.phone.replace(/[^0-9]/g, '')
                }).then(function (response) {
                    $scope.pts = response.data;
                    $scope.newPtDisabled = false;
                    for (var zz = 0; zz < $scope.pts.length; zz++) {
                        $scope.pts[zz].score = Math.round(($scope.pts[zz].score * 100)) + "%";
                        if ($scope.pts[zz].score === '100%') {
                            $scope.newPtDisabled = true;
                        }
                    }
                    if ($scope.pts.length > 0) {
                        $mdDialog.show({
                            controller: 'SelectPatientCtrl',
                            templateUrl: 'dashboard/selectPatient.html',
                            locals: {pts: $scope.pts, polst: $scope.adform, newPtDisabled: $scope.newPtDisabled},
                            parent: document.body,
                            clickOutsideToClose: true,
                            fullscreen: true
                        }).then(function (response) {
                            $scope.searching = false;
                            if (response !== undefined && response.confirmed) {
                                $scope.patientSelected = true;
                                $scope.ptSearchDone = true;
                                $scope.patientMatched = true;
                                if (response.patient !== undefined) {
                                    $scope.adform.patientID = response.patient.id;
                                    var patient = response.patinet;
                                    $scope.adform.dob = patient.dob;
                                    $scope.adform.fname = patient.fname;
                                    $scope.adform.lname = patient.lname;
                                    $scope.adform.mname = patient.mname;
                                    $scope.adform.street = patient.street;
                                    $scope.adform.city = patient.city;
                                    $scope.adform.state = patient.state;
                                    $scope.adform.zip = patient.zip;
                                    $scope.adform.phone = patient.phone;
                                    $scope.phone = patient.phone;
                                    $scope.adform.ssn = patient.ssn;
                                    $scope.adform.gender = patient.gender;
                                }
                                $scope.adform.sigName = $scope.adform.fname + " " + ($scope.adform.mname === undefined ? "" : $scope.adform.mname) + " " + $scope.adform.lname;
                                $scope.adform.sigStreet = $scope.adform.street;
                                $scope.adform.sigCity = $scope.adform.city;
                                $scope.adform.sigState = $scope.adform.state;
                                $scope.adform.sigZip = $scope.adform.zip;
                                $scope.adform.sigPhone = $scope.adform.phone;

                                userService.checkIfAD($scope.adform.patientID).then(function (checkResponse) {
                                    if(checkResponse.data.hasAD === true){
                                        $scope.isADdisabled = false;
                                        searchForCurrent();

                                        $mdDialog.show({
                                            controller: 'PublishOrArchiveAdCtrl',
                                            templateUrl: 'data-entry/publish-or-archive-ad.html',
                                            locals: {adform: $scope.adform},
                                            parent: document.body,
                                            clickOutsideToClose: false,
                                            fullscreen: true
                                        }).then(function (response) {
                                            console.log(response);
                                            if (response !== undefined) {
                                                if (response.reject === true) {
                                                    $scope.rejectForm();
                                                } else {
                                                    $scope.publish = response.publish;
                                                }
                                            }
                                        });
                                    }
                                    if(checkResponse.data.hasAD === false){
                                        $scope.isADdisabled = true;
                                        $scope.publish = 1;

                                    }

                                });
                            }
                        });
                    } else {
                        $scope.searching = false;
                        $scope.patientSelected = true;
                        $scope.ptSearchDone = true;
                        $scope.patientMatched = true;
                        $scope.adform.sigName = $scope.adform.fname + " " + ($scope.adform.mname === undefined ? "" : $scope.adform.mname) + " " + $scope.adform.lname;
                        $scope.adform.sigStreet = $scope.adform.street;
                        $scope.adform.sigCity = $scope.adform.city;
                        $scope.adform.sigState = $scope.adform.state;
                        $scope.adform.sigZip = $scope.adform.zip;
                        $scope.adform.sigPhone = $scope.adform.phone;
                    }
                });
            }

        };
        $scope.statusChanged = function () {
            if ($scope.adform.codeStatus === 0) {
                $scope.adform.end_of_life_decision = 'DNR';
            } else {
                $scope.adform.end_of_life_decision = 'Full CodetaChanged';
            }
        };
        $scope.authorityChanged = function () {
            if ($scope.adform.agentAuthority === 0) {
                $scope.adform.agent_authority = 'immediate';
            } else {
                $scope.adform.agent_authority = 'incapacitated';
            }
        };
        $scope.validateDOB = function(dateStr){
           $scope.invalidDOB = !helper.validateDate(dateStr);
        };
        $scope.validateDocDate = function(dateStr){
           $scope.invalidDocDate = !helper.validateDate(dateStr);
        };

        $scope.submitAD = function () {

            $scope.myPromise = dataEntryService.uploadAdvancedDirective($scope.picFile, $scope.adform, $scope.publish===1, $scope.selectedOrg.id).then(function (response) {
                $mdToast.show({
                    hideDealy: 10000,
                    position: 'bottom right',
                    controller: 'ToastSuccessCtrl',
                    templateUrl: 'data-entry/upload-success.html'
                });

                $scope.picFile = undefined;
                $scope.selectedOrg = undefined;
                reinit();
            });
        };
        $scope.selectedOrg = undefined;
        $scope.searchTextChange = function (text) {
            $scope.selectedOrg = undefined;
        };
        $scope.selectedItemChange = function (item) {
            $scope.selectedOrg = item;
        };
        $scope.retrieveOrg = function (l) {
            return dataEntryService.retrieveOrg($scope.user.sessionID, l).then(function (data) {
                return data;
            }, function (e) {
                return [];
            });
        };

    };
    ADUploadCtrl.$inject = ['$scope', 'userService', 'user', 'dataEntryService', '$routeParams', '$filter', 'helper', '$mdDialog', '$mdToast'];
    return ADUploadCtrl;
});

