define('user/covid-signup.controller',['angular'], function (angular) {
    'use strict';
    var CovidSingupCtrl = function ($scope, helper, userService, $location, $document) {
        // form controls
        $scope.cell_phone = '';
        $scope.validLogin = false;
        $scope.loginSubmitted = false;
        $scope.isProcessing = false;
        $scope.mismatch = false;
        $scope.error = false;
        $scope.pwUsed = false;
        $scope.busyTxt = '';
        $scope.signupDone = false;
        $scope.signup = function () {
            $scope.busyTxt = 'Validating NPI and Email';
            if ($scope.userInfo.password !== $scope.userInfo.password2) {
                $scope.mismatch = true;
            } else if (!$scope.isProcessing) {
                $scope.isProcessing = true;
                $scope.myPromise = userService.checkEmail($scope.userInfo, $scope).then(function (res) {
                    if (res.status === 200) {
                        userService.validateNPI({npi: $scope.userInfo.npi}).then(function (response) {
                            if (response.data.result_count === 1) {
                                $scope.npiInvalid = false;
                                $scope.isProcessing = false;
                                $scope.signupDone = true;
                            } else {
                                $scope.npiInvalid = true;
                                $scope.isProcessing = false;
                            }
                        }, function (err) {
                            $scope.npiInvalid = true;
                            $scope.isProcessing = false;
                        });
                    } else {
                        $scope.isProcessing = false;
                    }

                }, function(){
                    $scope.isProcessing = false;
                });
            }
        };
        $scope.passwordUpdate = function () {
            $scope.mismatch = false;
            $scope.error = false;
            $scope.pwUsed = false;
        };

        // cell phone
        $scope.showCode = false;
        $scope.verified = false;
        $scope.showResend = false;
        $scope.phoneUnique = true;
        $scope.sendCode = function () {
            userService.sendSMSVerifyCodeNotLoggedIn($scope.userInfo.cell_phone).then(function (response) {
                $scope.showCode = true;
            }, function () {
                $scope.phoneUnique = false;
            });
        };
        $scope.timer = 30;
        var showTimer = function () {
            $scope.timer = $scope.timer - 1;
            $scope.$apply();
            if ($scope.timer > 0) {
                setTimeout(showTimer, 1000);
            } else {
                $scope.showResend = true;
            }
        };
        $scope.resend = function () {
            $scope.codeError = false;
            $scope.verifyError = false;
            userService.sendSMSVerifyCodeNotLoggedIn($scope.userInfo.cell_phone).then(function (response) {
            }, function () {
            });
        };
        $scope.phoneChanged = function () {
            $scope.phoneUnique = true;
        };
        $scope.removeVerifyError = function () {
            $scope.verifyError = false;
        };
        $scope.verifying = false;
        $scope.verify = function () {
            if(!$scope.verifying) {
                $scope.verifying = true;
                $scope.busyTxt = 'Verifying phone and creating account';
                $scope.myPromise = userService.covidSignup($scope.userInfo, $scope).then(function (response) {
                    $scope.verifying = false;
                    if (response.data.sms_verify_error) {
                        $scope.codeError = true;
                        $scope.verifyError = true;
                    } else {
                        // signup valid, login and send to dashboard
                        $location.search({});
                        $location.path("dashboard");
                    }
                }, function(){
                    $scope.verifying = false;
                });
            }
        };

        // email
        $scope.emailExists = false;
        $scope.credentialsChanged = function () {
            $scope.loginSubmitted = false;
            $scope.emailExists = false;
        };

        // NPI
        $scope.npiSearchSubmitted = false;
        $scope.npiInvalid = false;
        $scope.npiChanged = function () {
            $scope.npiInvalid = false;
        };

        // vouched verify ID:
        $scope.vouchedReady = false;
        $scope.userInfo = {
            firstName: '',
            lastName: '',
            birthDate: '',
            street: '',
            city: '',
            state: '',
            zip: '',
            vouchedUUID: '',
            cell_phone: '',
            verificatoin_code: '',
            npi: '',
            email: '',
            password: '',
        };
        userService.generateUUID().then(function (response) {
            window.vouchedSession = response.data.UUID;
            $scope.userInfo.vouchedUUID = response.data.UUID;
            $scope.vouchedReady = true;
        });
        $scope.cell_phone = '';
        $scope.hasCamera = true;
        $scope.hasPermission = true;
        $scope.verifyDone = false;
        $scope.verifySuccess = false;
        $scope.linkSent = false;
        $scope.sendLink = function () {
            userService.textLink($scope.cell_phone).then(function(){
                $scope.linkSent = true;
            });
        };
        window.vouchedOnCamera = function (params) {
            $scope.$apply(function () {
                $scope.hasCamera = params.hasCamera;
                $scope.hasPermission = params.hasPermission;
            });
            if (!params.hasCamera || !params.hasPermission) {
            }
        };
        window.vouchedOnInit = function (params) {
        };
        window.vouchedOnDone = function (params) {
            $scope.$apply(function () {
                $scope.verifyDone = true;
                var success = params.result.confidences.faceMatch > 0.9;
                $scope.verifySuccess = success;
                $scope.userInfo.firstName = params.result.firstName;
                $scope.userInfo.lastName = params.result.lastName;
                $scope.userInfo.birthDate = params.result.birthDate;
                if (params.result.idAddress !== undefined) {
                    $scope.userInfo.street = params.result.idAddress.streetNumber + ' ' + params.result.idAddress.street;
                    $scope.userInfo.city = params.result.idAddress.city;
                    $scope.userInfo.state = params.result.idAddress.state;
                    $scope.userInfo.zip = params.result.idAddress.postalCode;
                }
            });
        };
        window.vouchedOnSubmit = function (params) {
            console.log('submit', params);
        };
    };
    CovidSingupCtrl.$inject = ['$scope', 'helper', 'userService', '$location', '$document'];
    return CovidSingupCtrl;
});

