define('dashboard/dashboard.controller',['angular'], function (angular) {
    'use strict';
    var DashboardCtrl = function ($scope, user, $location, $routeParams, $filter, $localStorage, $mdToast, $document,
                                  userService, $window, $mdDialog, $sce, helper, $q, iceService) {
        $scope.user = user.data.User;
        if ($scope.user.smartSession !== undefined) {
            userService.setIsSmart(true);
        }
        // if (window.emulateLLUMC) {
        $scope.isTest = !window.isProd;
        if (window.emulateLLUMC) {
            // $scope.user.smartSession = {
            //     lname: 'Smart',
            //     fname: 'Wilma',
            //     dob: '03/16/1947',
            //     gender: 'Female'
            // };
            // $scope.user.smartSession = {
            //     isLLUMC: false,
            //     lname: 'Smart',
            //     fname: 'Wilma',
            //     dob: '03/16/1947',
            //     gender: 'Female'
            // };
            $scope.user.smartSession = {
                isLLUMC: true,
                fname: 'Himscantestone',
                lname: 'Bridges',
                gender: 'Male',
                dob: '01/17/1994',
                city: 'Temecula',
                line: '29940 Rancho California Rd',
                state: 'CA',
                postalCode: '92591'
            };
            userService.setIsSmart(true);
            // $scope.user.npi = '1234';
            // $scope.user.smartSession = {
            //     isLLUMC: true,
            //     fname: 'IP ORDER',
            //     lname: 'ZZZTESTPOLSTFORTYSEVEN',
            //     dob: '03/08/1919'
            // };
            // }
            $scope.isTest = !window.isProd;

            // for ice patient demographics
            $scope.ice_id = Number($routeParams.p);
            if ($scope.ice_id !== undefined && $scope.ice_id > 0) {
                iceService.getIcePatient($scope.ice_id).then(function (i) {
                    $scope.polst = i.data[0];
                });
            }
        }
        $scope.loadedSmartContext = false;
        $scope.showSelectedOrg = function () {
            for (var ii = 0; ii < $scope.user.organizations.length; ii++) {
                if (Number($localStorage.pref_org_id) === $scope.user.organizations[ii].id) {
                    $scope.selectedOrgName = $scope.user.organizations[ii].name;
                }
            }
        };
        $scope.showSelectOrg = function () {
            $mdDialog.show({
                controller: 'SelectOrgCtrl',
                templateUrl: 'user/select-org.html',
                parent: angular.element(document.body),
                clickOutsideToClose: false,
                locals: {user: $scope.user},
                fullscreen: true,
                escapeToClose: false
            }).then(function (good) {
                if (good !== true) {
                    $scope.showSelectOrg();
                } else {
                    $scope.showSelectedOrg();
                }
            });
        };
        $scope.showVerify = function () {
            $mdDialog.show({
                controller: 'VerifyPhysicianCtrl',
                templateUrl: 'user/verifyPhysician.html',
                parent: angular.element(document.body),
                clickOutsideToClose: false,
                fullscreen: true,
                escapeToClose: false
            }).then(function (good) {
                if (good !== true) {
                    $scope.showVerify();
                } else if ($scope.user.organizations.length > 1 && ($localStorage.pref_org_id === undefined || $localStorage.pref_org_id <= 0)) {
                    $scope.showSelectOrg();
                } else {
                    $scope.showSelectedOrg();
                }
            });

        };
        $scope.logos = [{
            url: 'ahmc_logo.png'
        }, {
            url: 'chsb_logo.jpg'
        }, {
            url: 'cm_logo.png'
        }, {
            url: 'epic_logo.png'
        }, {
            url: 'iehp_logo.png'
        }, {
            url: 'k_logo.jpeg'
        }, {
            url: 'll_logo.jpeg'
        }, {
            url: 'pc_logo.gif'
        }, {
            url: 'pch_logo.jpeg'
        }, {
            url: 'rch_logo.png'
        }, {
            url: 'riversidech_logo.png'
        }, {
            url: 'rmc_logo.jpg'
        }, {
            url: 'sah_logo.png'
        }, {
            url: 'sbmc_logo.jpg'
        }];
        if ($scope.user.phyiscian === true && $scope.user.isPhysicianVerified === false) {
            $scope.showVerify();
        } else if ($scope.user.organizations.length > 1 && ($localStorage.pref_org_id === undefined || $localStorage.pref_org_id <= 0)) {
            $scope.showSelectOrg();
        } else {
            $scope.showSelectedOrg();
        }

        $scope.windowClose = function () {
            window.open('', '_self').close();
        };

        $scope.viewPDF = function () {
            if ($scope.fileUrl !== undefined) {
                window.open($scope.fileURL, "_blank");
            } else {
                // window.open('/#/view-pdf?id=' + $scope.polst.id + '&sid=' + $scope.user.sessionID, 'POLST', 'width=1000,resizable=1');
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    $scope.msFile = $scope.existingFile;
                    $scope.fileURL = 'tmp.pdf';
                    // window.navigator.msSaveOrOpenBlob(file, 'polst.pdf');
                    $scope.showDownload = true;
                    window.open('/#/view-pdf?id=' + $scope.polst.id + '&sid=' + $scope.user.sessionID, 'POLST', 'width=1000,resizable=1');
                } else {
                    $scope.showDownload = true;
                    $scope.fileURL = URL.createObjectURL($scope.existingFile);
                    window.open($scope.fileURL, "_blank");
                }
            }
        };
        $scope.physEmailChanged = function () {
            $scope.physician.selectedItem = undefined;
        };

        $scope.viewADPDF = function () {
            if ($scope.fileUrl !== undefined) {
                window.open($scope.fileAD_URL, "_blank");
            } else {
                userService.downloadADForm($scope.polst.patientID).then(function (response) {
                    var file = new Blob([response.data], {type: "application/pdf"});
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        $scope.msFileAD = file;
                        $scope.fileAD_URL = 'tmp.pdf';
                        // window.navigator.msSaveOrOpenBlob(file, 'polst.pdf');
                        $scope.showDownload = true;
                        window.navigator.msSaveOrOpenBlob($scope.msFileAD, 'polst.pdf');
                    } else {
                        $scope.showDownload = true;
                        $scope.fileAD_URL = URL.createObjectURL(file);
                        window.open($scope.fileAD_URL, "_blank");
                    }
                });
            }
        };

        $scope.downloadForm = function (form) {
            // window.open('/#/view-pdf?id=' + form.id + '&sid=' + $scope.user.sessionID, 'POLST', 'width=1000,resizable=1');
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.open('/#/view-pdf?id=' + form.id + '&sid=' + $scope.user.sessionID, 'POLST', 'width=1000,resiable=1');
            } else {
                window.open($scope.fileURL, "_blank");
            }
        };
        $scope.downloadDNRForm = function (form) {
            window.open($scope.fileDNR_URL, "_blank");
        };
        $scope.downloadDPOAForm = function (form) {
            window.open($scope.fileDPOA_URL, "_blank");
        };

        $scope.downloadADForm = function (form,ad) {
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                if (form === undefined) {
                    window.open('/#/view-pdf?id=' +ad.id + '&mode=ad&sid=' + $scope.user.sessionID, 'POLST', 'width=1000,resizable=1');

                } else {
                    window.open('/#/view-pdf?id=' + ad.id + '&mode=ad&sid=' + $scope.user.sessionID, 'POLST', 'width=1000,resiable=1');
                }
            } else {
                window.open(ad.fileAD_URL, "_blank");
            }
        };

        $scope.selectPt = function () {
            $scope.searching = false;
            $scope.patientSelected = true;
            $scope.ptSearchDone = true;
            $scope.patientMatched = true;
            $scope.polst.sigName = $scope.polst.fname + " " + ($scope.polst.mname === undefined ? "" : $scope.polst.mname) + " " + $scope.polst.lname;
            $scope.polst.sigStreet = $scope.polst.street;
            $scope.polst.sigCity = $scope.polst.city;
            $scope.polst.sigState = $scope.polst.state;
            $scope.polst.sigZip = $scope.polst.zip;
            $scope.polst.sigPhone = $scope.polst.phone;
        };
        $scope.newPtDisabled = false;
        $scope.searching = false;
        $scope.searchPts = function () {
            if (!$scope.searching) {
                $scope.searching = true;
                userService.matchPatient({
                    lname: $scope.polst.lname,
                    fname: $scope.polst.fname,
                    mname: $scope.polst.mname,
                    street: $scope.polst.street,
                    city: $scope.polst.city,
                    state: $scope.polst.state,
                    zip: $scope.polst.zip,
                    dob: $scope.polst.dob,
                    ssn: $scope.polst.ssn,
                    gender: $scope.polst.gender,
                    ins: $scope.polst.ins,
                    phone: $scope.polst.phone === undefined ? undefined : $scope.polst.phone.replace(/[^0-9]/g, '')
                }).then(function (response) {
                    $scope.pts = response.data;
                    $scope.newPtDisabled = false;
                    for (var zz = 0; zz < $scope.pts.length; zz++) {
                        $scope.pts[zz].score = Math.round(($scope.pts[zz].score * 100)) + "%";
                        if ($scope.pts[zz].score === '100%') {
                            $scope.newPtDisabled = true;
                        }
                    }
                    if ($scope.pts.length > 0) {
                        $mdDialog.show({
                            controller: 'SelectPatientCtrl',
                            templateUrl: 'dashboard/selectPatient.html',
                            locals: {pts: $scope.pts, polst: $scope.polst, newPtDisabled: $scope.newPtDisabled},
                            parent: document.body,
                            clickOutsideToClose: true,
                            fullscreen: true
                        }).then(function (response) {
                            $scope.searching = false;
                            if (response !== undefined && response.confirmed) {
                                $scope.patientSelected = true;
                                $scope.ptSearchDone = true;
                                $scope.patientMatched = true;
                                if (response.patient !== undefined) {
                                    console.log(response.patient);
                                    $scope.polst.patientID = response.patient.id;
                                    var patient = response.patient;
                                    $scope.polst.dob = patient.dob;
                                    $scope.polst.fname = patient.fname;
                                    $scope.polst.lname = patient.lname;
                                    $scope.polst.mname = patient.mname;
                                    $scope.polst.street = patient.street;
                                    $scope.polst.city = patient.city;
                                    $scope.polst.state = patient.state;
                                    $scope.polst.zip = patient.zip;
                                    $scope.polst.phone = patient.phone;
                                    $scope.phone = patient.phone;
                                    $scope.polst.ssn = patient.ssn;
                                    $scope.polst.gender = patient.gender;
                                    if ($scope.polst.gender === 'MALE') {
                                        $scope.polst.gender = 'Male';
                                    } else if ($scope.polst.gender === 'FEMALE') {
                                        $scope.polst.gender = 'Female';
                                    }
                                }
                                $scope.polst.sigName = $scope.polst.fname + " " + ($scope.polst.mname === undefined ? "" : $scope.polst.mname) + " " + $scope.polst.lname;
                                $scope.polst.sigStreet = $scope.polst.street;
                                $scope.polst.sigCity = $scope.polst.city;
                                $scope.polst.sigState = $scope.polst.state;
                                $scope.polst.sigZip = $scope.polst.zip;
                                $scope.polst.sigPhone = $scope.polst.phone;
                                if ($scope.user.smartSession !== undefined && $scope.user.smartSession.isLLUMC && $scope.user.npi !== undefined && $scope.user.npi.length > 0) {
                                    $scope.polst.supervisingPhys = $scope.user.firstname + ' ' + $scope.user.lastname;
                                }

                            }

                        });
                    } else {
                        $scope.searching = false;
                        $scope.patientSelected = true;
                        $scope.ptSearchDone = true;
                        $scope.patientMatched = true;
                        $scope.polst.sigName = $scope.polst.fname + " " + ($scope.polst.mname === undefined ? "" : $scope.polst.mname) + " " + $scope.polst.lname;
                        $scope.polst.sigStreet = $scope.polst.street;
                        $scope.polst.sigCity = $scope.polst.city;
                        $scope.polst.sigState = $scope.polst.state;
                        $scope.polst.sigZip = $scope.polst.zip;
                        $scope.polst.sigPhone = $scope.polst.phone;
                        if ($scope.user.smartSession !== undefined && $scope.user.smartSession.isLLUMC && $scope.user.npi !== undefined && $scope.user.npi.length > 0) {
                            $scope.polst.supervisingPhys = $scope.user.firstname + ' ' + $scope.user.lastname;
                        }
                    }
                });
            }
        };
        $scope.createNew = function () {
            $scope.hideCreate = false;
            var cpy = $scope.polst;
            $scope.polst = {};
            reset();
            $scope.newPt = false;
            $scope.patientSelected = true;
            $scope.ptSearchDone = true;
            $scope.patientMatched = true;
            $scope.polst.fname = cpy.fname;
            $scope.polst.mname = cpy.mname;
            $scope.polst.lname = cpy.lname;
            $scope.polst.street = cpy.street;
            $scope.polst.city = cpy.city;
            $scope.polst.state = cpy.state;
            $scope.polst.zip = cpy.zip;
            $scope.polst.dob = cpy.dob;
            $scope.polst.ins = cpy.ins;
            $scope.polst.ssn = cpy.ssn;
            $scope.polst.gender = cpy.gender;
            $scope.polst.phone = cpy.phone;
            $scope.polst.patientID = cpy.patient.id;
            $scope.polst.sigName = $scope.polst.fname + " " + ($scope.polst.mname === undefined ? "" : $scope.polst.mname) + " " + $scope.polst.lname;
            $scope.polst.sigStreet = $scope.polst.street;
            $scope.polst.sigCity = $scope.polst.city;
            $scope.polst.sigState = $scope.polst.state;
            $scope.polst.sigZip = $scope.polst.zip;
            $scope.polst.sigPhone = $scope.polst.phone;
            $scope.isEmpty = false;
            $scope.savedSig = false;
            $scope.polst.sigURL = undefined;
            $scope.isEmpty2 = false;
            $scope.savedSig2 = false;
            $scope.polst.physSigURL = undefined;
            if ($scope.user.smartSession !== undefined && $scope.user.smartSession.isLLUMC && $scope.user.npi !== undefined && $scope.user.npi.length > 0) {
                $scope.polst.supervisingPhys = $scope.user.firstname + ' ' + $scope.user.lastname;
            }
            if ($scope.user.smartSession !== undefined && $scope.user.smartSession.isLLUMC) {
                setTimeout(function () {
                    if ($scope.form.pform !== undefined) {
                        $scope.form.pform.$submitted = true;
                        $scope.form.pform.$pristine = false;
                    }
                    $scope.formSubmitted = true;
                }, 500);
            }
            $mdToast.show({
                hideDealy: 15000,
                position: 'bottom right',
                controller: 'FinishNewPolstCtrl',
                templateUrl: 'dashboard/finish-new-polst.html'
            });
        };
        $scope.patientSelected = false;
        $scope.resetResults = function () {
            $scope.numResults = false;
            $scope.oneResult = false;
            $scope.showDownload = false;
            $scope.showPreview = false;
        };
        $scope.viewExisting = function (id) {
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.open('/#/view-pdf?id=' + id + '&sid=' + $scope.user.sessionID, 'POLST', 'width=1000,resizable=1');
            } else {
                userService.downloadForm(id).then(function (response) {
                    var file = new Blob([response.data], {type: "application/pdf"});
                    $scope.showDownload = true;
                    $scope.fileURL = URL.createObjectURL(file);
                    window.open($scope.fileURL, "_blank");
                });
            }

        };
        $scope.ptSelected = function (p) {
            $scope.patientMatched = true;
            if (p !== undefined) {
                $scope.polst.patientID = p.id;
            }
            $scope.polst.sigName = $scope.polst.fname + " " + ($scope.polst.mname === undefined ? "" : $scope.polst.mname) + " " + $scope.polst.lname;
            $scope.polst.sigStreet = $scope.polst.street;
            $scope.polst.sigCity = $scope.polst.city;
            $scope.polst.sigState = $scope.polst.state;
            $scope.polst.sigZip = $scope.polst.zip;
            $scope.polst.sigPhone = $scope.polst.phone;
        };
        $scope.physician = {};
        $scope.physicianSearch = function (txt) {
            if (txt.length >= 2) {
                return userService.searchPhysicians(txt).then(function (data) {
                    for (var z = 0; z < data.length; z++) {
                        data[z].fullname = data[z].firstname + " " + data[z].lastname;
                    }
                    return data;
                });
            }
            return [];
        };
        // $scope.hospiceInvalid = true;
        $scope.hospiceChanged = function () {
            // $scope.hospiceInvalid = true;
            if ($scope.polst.onHospice === 0) {
                $scope.hospiceInvalid = false;
            } else {
                if ($scope.hospice.selectedItem !== undefined && $scope.hospice.selectedItem !== null) {
                    $scope.hospiceInvalid = false;
                }
            }
        };
        $scope.hospice = {};
        $scope.hospiceSearch = function (txt) {
            if (txt.length >= 1) {
                return userService.searchHospice(txt).then(function (data) {
                    return data;
                });

            }
            return [];
        };
        $scope.sig = {};
        $scope.sig2 = {};
        $scope.formTemplate = "dashboard/POLST.html";
        $scope.addForm = function () {
            $location.path("/addform");
        };
        $scope.secAChanged = function () {
            if ($scope.polst.secA === 0 && $scope.polst.secB !== 0) {
                $scope.polst.secB = undefined;
                $scope.uncheckB();
            } else if ($scope.polst.secA === 1 && $scope.polst.secB === 0) {
                // $scope.polst.secB = undefined;
                // $scope.uncheckB();
            }
        };
        $scope.resetEmpty2 = function () {
            $scope.isEmpty2 = false;
        };
        $scope.clearSig2 = function () {
            $scope.isEmpty2 = false;
            $scope.savedSig2 = false;
            $scope.polst.physSigURL = undefined;
        };
        $scope.sign2 = function (sig2) {
            if (sig2.isEmpty) {
                $scope.isEmpty2 = true;
            } else {
                $scope.isEmpty2 = false;
                $scope.savedSig2 = true;
                $scope.polst.physSigURL = sig2.dataUrl;
                if (!$scope.polst.signed && $scope.hideCreate) {
                    if ($scope.polst.physSigURL !== undefined && $scope.polst.physSigURL.length > 0) {
                        var confirm = $mdDialog.confirm().title("Execute this form?").textContent("Signing will finalize this form.").ok("Sign").cancel('Cancel');
                        $mdDialog.show(confirm)
                            .then(function () {
                                $scope.busyMsg = 'Saving Form ...';
                                $scope.myPromise = userService.signForm({
                                    formid: $scope.polst.id,
                                    physSigURL: $scope.polst.physSigURL
                                }).then(function () {
                                    $location.search({p: 0});
                                });
                            }, function () {
                                $scope.clearSig2();
                            });
                    } else {
                        $mdDialog.show($mdDialog.alert()
                            .clickOutsideToClose(true)
                            .title('Information missing')
                            .textContent('Please save your signature and fill out the name, phone number, and license number information above.')
                            .ok('Got it!'));
                    }
                }
            }
        };
        var validPhysSign = function () {
            return true;
        };
        $scope.resetEmpty = function () {
            $scope.isEmpty = false;
        };
        $scope.clearSig = function () {
            $scope.isEmpty = false;
            $scope.savedSig = false;
            $scope.polst.sigURL = undefined;
        };
        $scope.sign = function (sig) {
            if (sig.isEmpty) {
                $scope.isEmpty = true;
            } else {
                $scope.isEmpty = false;
                $scope.savedSig = true;
                $scope.polst.sigURL = sig.dataUrl;
            }
        };

        $scope.uncheckB = function () {
            $scope.polst.secB2a = false;
            $scope.polst.secB1a = false;
        };
        $scope.form = {};
        $scope.swapTabs = function (idx) {
            $scope.tabIndex = idx;
            if (idx === 1) {
                $location.search('p', idx);
            } else {

                $location.search({p: idx});
            }
        };
        $scope.editPatient = function (form, e) {
            $mdDialog.show({
                controller: 'EditPatientCtrl',
                templateUrl: 'dashboard/editPatient.html',
                locals: {form: form, user: $scope.user},
                parent: document.body,
                targetEvent: e,
                clickOutsideToClose: true,
                fullscreen: true
            }).then(function (response) {
                if (response) {
                    $mdToast.show({
                        hideDealy: 10000,
                        position: 'bottom right',
                        controller: 'ToastCtrl',
                        templateUrl: 'dashboard/patientSavedToast.html',
                        locals: {credentials: response, orgs: $scope.orgs, admin: $scope.user}
                    });
                }
            });
        };
        $scope.openForm = function (form) {
            $scope.viewExistingForm = true;
            $location.search({p: 1, f: form.id});
        };
        $scope.forms = [];
        $scope.search = {lname: '', fname: '', dob: ''};
        $scope.resetSearchForm = function () {
            $scope.search = {lname: '', fname: '', dob: ''};
            $scope.searchErr = false;
            $scope.numResults = false;
            $scope.oneResult = false;
            $scope.showDownload = false;
            $scope.showPreview = false;
            $scope.search.dob = "";
            $scope.invalidSearchDOB = true;
            setTimeout(function () {
                $scope.search.dob = undefined;
            });
        };
        $scope.viewPOLST = function (form) {
        };
        $scope.downloadAD = function (ad) {
            userService.downloadADById(ad.id).then(function (response) {
                ad.isADdisabled = false;
                var file = new Blob([response.data], {type: "application/pdf"});
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    ad.msFileAD = file;
                    ad.fileAD_URL = ad.doc_type + '.pdf';
                    // window.navigator.msSaveOrOpenBlob(file, 'polst.pdf');
                } else {
                    ad.showADDownload = true;
                    ad.fileAD_URL = URL.createObjectURL(file);
                }
            });
        };
        $scope.searchForms = function () {
            $scope.searchErr = false;
            $scope.numResults = false;
            $scope.oneResult = false;
            $scope.showDownload = false;
            $scope.showPreview = false;
            $scope.isADdisabled = false;
            $scope.fileURL = "";
            $scope.content = "";
            $scope.searchForm = undefined;
            $scope.searchPt = undefined;
            if ($scope.search !== undefined && $scope.search !== null && ($scope.search.dob === undefined || $scope.search.dob.length === 0 || ($scope.search.dob.length > 0 && !$scope.form.sform.dob.$invalid))) {
                $scope.myPromise2 = userService.searchForms($scope.search).then(function (response) {
                    setTimeout(function () {
                        if (Number($scope.p) === 0) {
                            helper.scrollContent("searchResults");
                        }
                    }, 1);
                    $scope.searchPt = response.data;
                    $scope.searchForm = $scope.searchPt.form;
                    if ($scope.searchForm !== undefined && $scope.searchForm.nullified_on !== undefined && $scope.searchForm.nullified_on.length > 10) {
                        $scope.searchForm.nullified_on = $scope.searchForm.nullified_on.substring(0, 10);
                    }
                    if ($scope.searchForm !== undefined) {
                        var tmp2 = moment.utc($scope.searchForm.created);
                        $scope.searchForm.createdDate = tmp2.tz('America/Los_Angeles').format('MM/DD/YYYY');
                    }
                    if ($scope.searchPt.num !== undefined) {
                        $scope.numResults = true;
                    } else if ($scope.searchPt !== undefined) {
                        $scope.oneResult = true;
                        if ($scope.searchPt.ads !== undefined) {
                            for (var i = 0; i < $scope.searchPt.ads.length; i++) {
                                var ad = $scope.searchPt.ads[i];
                                if (ad.type === 'living_will') {
                                    ad.doc_type = 'Living Will';
                                } else if (ad.type === 'hcpoa') {
                                    ad.doc_type = 'Health Care Power of Attorney';
                                } else if (ad.type === 'mhcpoa') {
                                    ad.doc_type = 'Mental Health Care Power of Attorney';
                                } else if (ad.type === 'prehopsital') {
                                    ad.doc_type = 'Prehospital Medical Care Directive';
                                } else {
                                    ad.doc_type = 'Advance Directive';
                                }
                                if (ad.nullified_on !== undefined && ad.nullified_on.length > 10) {
                                    $scope.searchPt.ads[i].nullified_on = ad.nullified_on.substring(0, 10);
                                }
                                $scope.searchPt.ads[i].showADDownload = true;
                                $scope.searchPt.ads[i].isADdisabled = true;
                                $scope.downloadAD(ad);
                            }
                        }
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            $scope.showDownload = true;
                        } else {
                            if ($scope.searchForm !== undefined) {
                                $scope.polstPDFBusy = userService.downloadForm($scope.searchPt.id).then(function (response) {
                                    $scope.showDownload = true;
                                    var file = new Blob([response.data], {type: "application/pdf"});
                                    $scope.showDownload = true;
                                    $scope.fileURL = URL.createObjectURL(file);
                                });
                            }
                        }
                        console.log('calling checkIfACP?');
                        userService.checkIfACP($scope.searchPt.id).then(function (checkResponse) {
                            $scope.noDPOAFOund = false;
                            $scope.isDPOAdisabled = true;
                            if (checkResponse.data.hasDPOA === true) {
                                userService.downloadACPForm($scope.searchPt.id, 'DPOA').then(function (response) {
                                    $scope.isDPOAdisabled = false;
                                    var file = new Blob([response.data], {type: "application/pdf"});
                                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                                        $scope.msFileDPOA = file;
                                        $scope.fileAD_URL = 'tmp.pdf';
                                        // window.navigator.msSaveOrOpenBlob(file, 'polst.pdf');
                                    } else {
                                        $scope.showDPOADownload = true;
                                        $scope.fileDPOA_URL = URL.createObjectURL(file);
                                    }
                                });

                            } else {
                                $scope.isDPOAdisabled = true;
                                $scope.noDPOAFOund = true;
                            }
                            $scope.isDNRdisabled = true;
                            $scope.noDNRFound = false;
                            if (checkResponse.data.hasDNR === true) {
                                userService.downloadACPForm($scope.searchPt.id, 'Hospital DNR').then(function (response) {
                                    $scope.isDNRdisabled = false;
                                    var file = new Blob([response.data], {type: "application/pdf"});
                                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                                        $scope.msFileDNR = file;
                                        $scope.fileAD_URL = 'tmp.pdf';
                                        // window.navigator.msSaveOrOpenBlob(file, 'polst.pdf');
                                    } else {
                                        $scope.showDNRDownload = true;
                                        $scope.fileDNR_URL = URL.createObjectURL(file);
                                    }
                                });

                            } else {
                                $scope.isDNRdisabled = true;
                                $scope.noDNRFound = true;
                            }

                        });
                    } else {
                        setTimeout(function () {
                            helper.scrollContent("searchResults");
                        }, 1);
                        $scope.searchErr = true;
                    }
                }, function () {
                    setTimeout(function () {
                        helper.scrollContent("searchResults");
                    }, 1);
                    $scope.searchErr = true;
                });
            }
        };
        var getForms = function () {
            // $scope.forms = [];
            //userService.getForms().then(function (response) {
            //   $scope.forms = response.data;
            //});
        };
        $scope.showDownloadExisting = false;
        var getForm = function () {
            $scope.loaded = false;
            $scope.adLoaded = false;
            $scope.hideCreate = true;
            $scope.patientMatched = true;
            $scope.showADDownloadExisting = false;
            $scope.busyMsg = 'Loading Form ...';
            $scope.myPromise = userService.getForm($scope.fid).then(function (response) {
                setTimeout(function () {
                    helper.scrollContent("createPolst");
                }, 100);
                $scope.polst = response.data;
                userService.downloadForm($scope.polst.patientID).then(function (response) {
                    $scope.existingFile = new Blob([response.data], {type: "application/pdf"});
                    $scope.showDownloadExisting = true;
                });
                console.log($scope.polst);
                var tmp = moment.utc($scope.polst.created, 'YYYY-MM-DD HH:mm:ss');
                if ($scope.polst !== undefined && $scope.polst !== null) {
                    $scope.polst.dateCreated = tmp.tz('America/Los_Angeles').format('hh:mm a MM/DD/YYYY z');
                    console.log('setting supervisingPhys = ' + $scope.polst.provName);
                    $scope.polst.supervisingPhys = $scope.polst.provName;
                    if ($scope.polst.physSigDate !== undefined) {
                        tmp = moment.utc($scope.polst.physSigDate, 'YYYY-MM-DD');
                        $scope.polst.datePhysSigned = tmp.format('MM/DD/YYYY');
                    }
                    $scope.polst.gender = $scope.polst.gender.substring(0, 1) + $scope.polst.gender.substring(1).toLowerCase();
                    if ($scope.polst.gender === 'MALE') {
                        $scope.polst.gender = 'Male';
                    } else if ($scope.polst.gender === 'FEMALE') {
                        $scope.polst.gender = 'Female';
                    }
                    if ($scope.polst.sigURL) {
                        $scope.savedSig = true;
                    }
                    if ($scope.polst.physSigURL) {
                        $scope.savedSig2 = true;
                    }
                    $scope.loaded = true;
                    userService.checkIfAD($scope.polst.patientID).then(function (checkResponse) {
                        $scope.adLoaded = true;
                        if (checkResponse.data.hasAD === true) {
                            $scope.showADDownloadExisting = true;
                            $scope.isADdisabledExisting = true;
                            userService.downloadADForm($scope.polst.patientID).then(function (response) {
                                $scope.isADdisabledExisting = false;
                                var file = new Blob([response.data], {type: "application/pdf"});
                                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                                    $scope.msFileAD = file;
                                    $scope.fileAD_URL = 'tmp.pdf';
                                    // window.navigator.msSaveOrOpenBlob(file, 'polst.pdf');
                                } else {
                                    $scope.showADDownload = true;
                                    $scope.fileAD_URL = URL.createObjectURL(file);
                                }
                            });
                        }
                        if (checkResponse.data.hasAD === false) {
                            $scope.isADdisabled = true;
                        }
                    });
                }
            });
        };
        $scope.fid = undefined;
        var reset = function () {
            $scope.newPt = false;
            $scope.physician.selectedItem = undefined;
            $scope.patientSelected = false;
            $scope.patientMatched = false;
            $scope.ptSearchDone = false;
            $scope.polst = {
                secD2: 2,
                gender: '',
                sigRelationship: 'Self',
                notCompByPhys: !$scope.user.phyiscian
            };
            if ($scope.sig.clear !== undefined) {
                $scope.sig.clear();
            }
            $scope.fid = undefined;
            $scope.formSubmitted = false;
            $scope.isEmpty = false;
            $scope.savedSig = false;
            $scope.savedSig2 = false;
            if ($scope.user.smartSession === undefined || !$scope.loadedSmartContext) {
                $scope.searchErr = false;
                $scope.numResults = false;
                $scope.oneResult = false;
                $scope.search = {lname: '', fname: '', dob: ''};
                $scope.searchForm = undefined;
                $scope.searchPt = undefined;
            }
            console.log('setting hideCreate to false in reset');
            $scope.hideCreate = false;
            if ($scope.form.sform !== undefined) {
                $scope.form.sform.$setPristine();
                $scope.form.sform.$setUntouched();
            }
            if ($scope.form.pform !== undefined) {
                $scope.form.pform.$setPristine();
                $scope.form.pform.$setUntouched();
            }
        };
        var reinit = function () {
            $scope.p = Number($routeParams.p);
            if ($scope.p === undefined || isNaN($scope.p)) {
                $scope.p = 0;
            }
            if ($scope.p !== 1 || $scope.user.smartSession === undefined || $scope.searchPt === undefined || $scope.searchPt.form === undefined) {
                reset();
            } else if ($scope.p === 1 && $scope.user.smartSession !== undefined && $scope.searchPt !== undefined && $scope.searchPt.form !== undefined) {
                $scope.polst = $scope.searchPt.form;
            }
            $scope.numToSign = 0;
            userService.numToSign().then(function (response) {
                if (response.data !== undefined) {
                    $scope.numToSign = response.data.num;
                }
            }, function (e) {

            });
            if ($location.search().f !== undefined) {
                setTimeout(function () {
                    $scope.fid = Number($routeParams.f);
                    getForm();
                }, 10);
            }
            $scope.tabIndex = $scope.p;
            if ($scope.user.smartSession !== undefined) {
                if ($scope.user.smartSession.gender !== undefined) {
                    $scope.polst.gender = $scope.user.smartSession.gender;
                    if (!$scope.loadedSmartContext) {
                        $scope.search.gender = $scope.user.smartSession.gender;
                        if ($scope.polst.gender === 'MALE') {
                            $scope.polst.gender = 'Male';
                        } else if ($scope.polst.gender === 'FEMALE') {
                            $scope.polst.gender = 'Female';
                        }
                    }
                }
                if ($scope.user.smartSession.mname !== undefined) {
                    $scope.polst.mname = $scope.user.smartSession.mname;
                    if (!$scope.loadedSmartContext) {
                        $scope.search.mname = $scope.user.smartSession.mname;
                    }
                }
                if ($scope.user.smartSession.lname !== undefined) {
                    $scope.polst.lname = $scope.user.smartSession.lname;
                    if (!$scope.loadedSmartContext) {
                        $scope.search.lname = $scope.user.smartSession.lname;
                    }
                }
                if ($scope.user.smartSession.fname !== undefined) {
                    $scope.polst.fname = $scope.user.smartSession.fname;
                    if (!$scope.loadedSmartContext) {
                        $scope.search.fname = $scope.user.smartSession.fname;
                    }
                }
                if ($scope.user.smartSession.line !== undefined) {
                    $scope.polst.street = $scope.user.smartSession.line;
                    if (!$scope.loadedSmartContext) {
                        $scope.search.street = $scope.user.smartSession.line;
                    }
                }
                if ($scope.user.smartSession.city !== undefined) {
                    $scope.polst.city = $scope.user.smartSession.city;
                    if (!$scope.loadedSmartContext) {
                        $scope.search.city = $scope.user.smartSession.city;
                    }
                }
                if ($scope.user.smartSession.state !== undefined) {
                    $scope.polst.state = $scope.user.smartSession.state;
                    if (!$scope.loadedSmartContext) {
                        $scope.search.state = $scope.user.smartSession.state;
                    }
                }
                if ($scope.user.smartSession.postalCode !== undefined) {
                    $scope.polst.zip = $scope.user.smartSession.postalCode;
                    if (!$scope.loadedSmartContext) {
                        $scope.search.zip = $scope.user.smartSession.postalCode;
                    }
                }
                if ($scope.user.smartSession.phone !== undefined) {
                    $scope.polst.phone = $scope.user.smartSession.phone;
                    if (!$scope.loadedSmartContext) {
                        $scope.search.phone = $scope.user.smartSession.phone;
                    }
                }
                if ($scope.user.smartSession.ssn !== undefined) {
                    $scope.polst.ssn = $scope.user.smartSession.ssn;
                    if (!$scope.loadedSmartContext) {
                        $scope.search.ssn = $scope.user.smartSession.ssn;
                    }
                }
                if ($scope.user.smartSession.dob !== undefined) {
                    $scope.polst.dob = $scope.user.smartSession.dob;
                    if (!$scope.loadedSmartContext) {
                        $scope.search.dob = $scope.user.smartSession.dob;
                        setTimeout(function () {
                            $scope.validateSearchDOB($scope.search.dob);
                        }, 10);
                    }
                }
                console.log('loaded smartContext? ' + $scope.loadedSmartContext);
                if (!$scope.loadedSmartContext) {
                    $scope.loadedSmartContext = true;
                    setTimeout(function () {
                        $scope.searchForms();
                    }, 100);
                }
            }
            if ($scope.p === 0) {
                getForms();
            } else if ($scope.p === 1) {
                if ($scope.user.smartSession !== undefined && !$scope.viewExistingForm) {
                    if ($scope.searchPt !== undefined && $scope.searchPt.form !== undefined) {
                        if (!$scope.suppressNew) {
                            $scope.createNew();
                        }
                        $scope.suppressNew = false;
                    } else {
                        $scope.newPt = true;
                    }
                }
                if ($scope.user.smartSession !== undefined && $scope.user.smartSession.isLLUMC) {
                    setTimeout(function () {
                        if ($scope.form.pform !== undefined) {
                            $scope.form.pform.$submitted = true;
                            $scope.form.pform.$pristine = false;
                        }
                        $scope.formSubmitted = true;
                    }, 1);
                }
            }
            $scope.viewExistingForm = false;
        };
        $scope.completePendingFromEPolst = function () {
            $mdDialog.show({
                controller: 'PolstCreateDialogCtrl',
                templateUrl: 'dashboard/polst-create-dialog.html',
                locals: {polst: $scope.polst, user: $scope.user},
                parent: document.body,
                escapeToClose: false,
                clickOutsideToClose: false,
                fullscreen: true
            }).then(function (response) {
                var confirm = $mdDialog.confirm().title("Form created").textContent("This POLST form has been successfully saved.").ok("View New Form").cancel('Got it!');
                $mdDialog.show(confirm)
                    .then(function (response2) {
                        if ($scope.user.smartSession !== undefined) {
                            $scope.loadedSmartContext = false;
                        }
                        $scope.suppressNew = true;
                        setTimeout(function () {
                            helper.scrollContent("createPolst");
                        }, 100);
                        $location.search({p: 1, f: response.data.id});
                    }, function (err) {
                        if ($scope.user.smartSession !== undefined) {
                            $scope.loadedSmartContext = false;
                        }
                        setTimeout(function () {
                            helper.scrollContent("searchResults");
                        }, 100);
                        setTimeout(function () {
                            reinit();
                        }, 10);
                        $location.search({p: 0});
                    });
            });
        };
        $scope.completePending = function () {
            $mdDialog.show({
                controller: 'PolstCreateDialogCtrl',
                templateUrl: 'dashboard/polst-create-dialog.html',
                locals: {polst: $scope.searchForm, user: $scope.user},
                parent: document.body,
                escapeToClose: false,
                clickOutsideToClose: false,
                fullscreen: true
            }).then(function (response) {
                var confirm = $mdDialog.confirm().title("Form created").textContent("This POLST form has been successfully saved.").ok("View New Form").cancel('Got it!');
                $mdDialog.show(confirm)
                    .then(function (response2) {
                        if ($scope.user.smartSession !== undefined) {
                            $scope.loadedSmartContext = false;
                        }
                        $scope.suppressNew = true;
                        setTimeout(function () {
                            helper.scrollContent("createPolst");
                        }, 100);
                        $location.search({p: 1, f: response.data.id});
                    }, function (err) {
                        if ($scope.user.smartSession !== undefined) {
                            $scope.loadedSmartContext = false;
                        }
                        setTimeout(function () {
                            helper.scrollContent("searchResults");
                        }, 100);
                        setTimeout(function () {
                            reinit();
                        }, 10);
                        $location.search({p: 0});
                    });
            });
        };

        $scope.socket = {};
        $scope.viewSignable = function () {
            $location.path("viewSignable").search({});
        };
        $scope.createSmartPolst = function () {
            $scope.swapTabs(1);
        };
        $scope.$on('$routeChangeSuccess', function () {
            reinit();
        });
        $scope.$on('$locationChangeSuccess', function () {
            reinit();
        });
        // POLST:
        var aoChange = false;
        $scope.taChanged = function () {
            aoChange = true;
        };
        $scope.genders = [{
            id: 1,
            name: "MALE"
        }, {
            id: 2,
            name: "FEMALE"
        }, {
            id: 3,
            name: 'UNDIFFERENTIATED'
        }];
        $scope.polst = {
            secD2: 2,
            onHospice: 0
        };
        var updateLabels = function () {
            helper.updatePolstLabels($scope, $filter, aoChange);
        };
        updateLabels();
        $scope.$watch(function () {
            return $localStorage.lang;
        }, function () {
            updateLabels();
        });
        var validContact = function () {
            if ($scope.polst.noProv || ($scope.polst.contactPhone !== undefined && $scope.polst.contactPhone.length > 0 && $scope.polst.contactTitle !== undefined && $scope.polst.contactTitle.length > 0 && $scope.polst.contactName !== undefined && $scope.polst.contactName.length > 0)) {
                return true;
            }
            return false;
        };
        var validAdvDate = function () {
            if ($scope.polst.secD2 !== '0' || ($scope.polst.advDate !== undefined && $scope.polst.advDate.length > 0 && !$scope.form.pform.advDate.$error.pattern && $scope.polst.agentName !== undefined && $scope.polst.agentName.length > 0 && $scope.polst.agentPhone !== undefined && $scope.polst.agentPhone.length > 0)) {
                return true;
            }
            return false;
        };
        $scope.formSubmitted = false;
        $scope.createForm = function (valid, ev) {
            $scope.formSubmitted = true;
            $scope.hospiceChanged();
            if ($scope.user.smartSession === undefined || !$scope.user.smartSession.isLLUMC) {
                if ((valid && !$scope.hospiceInvalid && ($scope.polst.secA >= 0 && $scope.polst.secB >= 0 && $scope.polst.secD1 >= 0 && $scope.polst.secD2 >= 0 && validContact() && validPhysSign() && validAdvDate()))) {
                    if ($scope.polst.secC === undefined) {
                        $scope.polst.secC = -1;
                    }
                    if ($scope.hospice.selectedItem !== undefined && $scope.hospice.selectedItem !== null) {
                        $scope.polst.hospiceID = $scope.hospice.selectedItem.id;
                    }
                    if ($scope.physician.selectedItem !== undefined && $scope.physician.selectedItem !== null) {
                        $scope.polst.physicianID = $scope.physician.selectedItem.userid;
                    } else {
                        $scope.polst.physicianID = 0;
                    }
                    $scope.polst.hospiceName = $scope.hospice.searchText;
                    $mdDialog.show({
                        controller: 'PolstCreateDialogCtrl',
                        templateUrl: 'dashboard/polst-create-dialog.html',
                        locals: {polst: $scope.polst, user: $scope.user},
                        parent: document.body,
                        clickOutsideToClose: false,
                        escapeToClose: false,
                        fullscreen: true
                    }).then(function (response) {
                        var confirm = $mdDialog.confirm().title("Form created").textContent("This POLST form has been successfully saved.").ok("View New Form").cancel('Got it!');
                        $mdDialog.show(confirm)
                            .then(function (response2) {
                                if ($scope.user.smartSession !== undefined) {
                                    $scope.loadedSmartContext = false;
                                }
                                setTimeout(function () {
                                    helper.scrollContent("createPolst");
                                }, 100);
                                $scope.suppressNew = true;
                                $location.search({p: 1, f: response.data.id});
                            }, function (err) {
                                setTimeout(function () {
                                    helper.scrollContent("searchResults");
                                }, 100);
                                if ($scope.user.smartSession !== undefined) {
                                    $scope.loadedSmartContext = false;
                                }
                                $location.search({p: 0});
                            });
                    });
                } else {
                    $mdToast.show({
                        templateUrl: 'dashboard/FormErrToast.html', position: 'bottom', hideDelay: 30000,
                        controller: 'ToastFormErrCtrl', parent: $document[0].querySelector('#buttonID')

                    });
                }
            } else {
                if (valid && !$scope.hospiceInvalid && $scope.polst.secA >= 0 && $scope.polst.secB >= 0 && $scope.polst.secD1 >= 0 && $scope.polst.secD2 >= 0 && validContact() && validAdvDate() && $scope.polst.supervisingPhys !== undefined && $scope.polst.supervisingPhys.length > 0) {
                    if ($scope.user.smartSession === undefined) {
                        if ($scope.polst.secC === undefined) {
                            $scope.polst.secC = -1;
                        }
                        if ($scope.hospice.selectedItem !== undefined && $scope.hospice.selectedItem !== null) {
                            $scope.polst.hospiceID = $scope.hospice.selectedItem.id;
                        }
                        if ($scope.physician.selectedItem !== undefined && $scope.physician.selectedItem !== null) {
                            $scope.polst.physicianID = $scope.physician.selectedItem.userid;
                        } else {
                            $scope.polst.physicianID = 0;
                        }
                    }
                    $mdDialog.show({
                        controller: 'PolstCreateDialogCtrl',
                        templateUrl: 'dashboard/polst-create-dialog.html',
                        locals: {polst: $scope.polst, user: $scope.user},
                        parent: document.body,
                        clickOutsideToClose: false,
                        escapeToClose: false,
                        fullscreen: true
                    }).then(function (response) {
                        $mdDialog.show($mdDialog.confirm().title("Form created").textContent("This POLST form has been successfully saved.").ok("View New Form").cancel('Got it!'))
                            .then(function (response2) {
                                if ($scope.user.smartSession !== undefined) {
                                    $scope.loadedSmartContext = false;
                                }
                                $scope.suppressNew = true;
                                $location.search({p: 1, f: response.data.id});
                            }, function (err) {
                                if ($scope.user.smartSession !== undefined) {
                                    $scope.loadedSmartContext = false;
                                }
                                $location.search({p: 0});
                            });
                    });
                } else {
                    $mdToast.show({
                        templateUrl: 'dashboard/FormErrToast.html', position: 'bottom', hideDelay: 30000,
                        controller: 'ToastFormErrCtrl', parent: $document[0].querySelector('#buttonID')
                    });
                }
            }
        };
        $scope.states = helper.getStates();
        $scope.invalidSearchDOB = true;
        $scope.validateSearchDOB = function (dateStr) {
            $scope.numResults = false;
            $scope.oneResult = false;
            $scope.showDownload = false;
            $scope.showPreview = false;
            $scope.invalidSearchDOB = !helper.validateDate(dateStr);
            if ($scope.user.smartSession !== undefined && $scope.user.smartSession.isLLUMC) {
                if ($scope.invalidSearchDOB) {
                    var content = 'got invalid search DOB! |' + dateStr + '| with strict: ' + moment(dateStr, 'MM/DD/YYYY', true).isValid() + ' without strict: ' + moment(dateStr, 'MM/DD/YYYY').isValid();
                    userService.smartSendEmailForLogging(content);
                }
            }
        };
        $scope.validateCreateDOB = function (dateStr) {
            $scope.invalidCreateDOB = !helper.validateDate(dateStr);
        };
        $scope.validateCreateAdvDate = function (dateStr) {
            $scope.invalidCreateAdvDate = !helper.validateDate(dateStr);
        };
        $scope.sendFax = function (form, e) {
            $mdDialog.show({
                controller: 'FaxFormCtrl',
                templateUrl: 'dashboard/fax-polst.html',
                locals: {form: form, user: $scope.user},
                parent: document.body,
                targetEvent: e,
                clickOutsideToClose: true,
                fullscreen: true
            }).then(function (response) {
            });
        };
        $scope.exportADToEHR = function (form) {
            if ($scope.user.smartSession.isLLUMC) {
                // $scope.myPromise3 = userService.putADToSFTP(form.advDirective.id).then(function () {
                //     window.alert('POLST form has been exported! This form will be available inside of Epic within 1 hour.');
                // });
            } else {
                if (form !== undefined) {
                    // TODO this will fail
                    $scope.myPromise3 = userService.exportADToEHR(form.advDirective.id).then(function () {
                        window.alert('exported!');
                    }, function () {
                        window.alert('An error occurred');
                    });
                } else {
                    $scope.busyMsg = 'Exporting ...';
                    $scope.myPromise = userService.exportADToEHR($scope.ADID).then(function () {
                        window.alert('exported!');
                    }, function () {
                        window.alert('An error occurred');
                    });
                }
            }
        };
        $scope.exportToEHR = function (form, isViewForm) {
            if ($scope.user.smartSession.isLLUMC) {
                if (isViewForm) {
                    $scope.busyMsg = 'Exporting ...';
                    $scope.myPromise = userService.putToSFTP(form.id).then(function () {
                        window.alert('POLST form has been exported! This form will be available inside of Epic within 1 hour.');
                    });

                } else {
                    $scope.myPromise3 = userService.putToSFTP(form.id).then(function () {
                        window.alert('POLST form has been exported! This form will be available inside of Epic within 1 hour.');
                    }, function () {
                        window.alert('An error occurred');
                    });
                }
            } else {
                if (isViewForm) {
                    $scope.busyMsg = 'Exporting ...';
                    $scope.myPromise = userService.exportToEHR(form.id).then(function () {
                        window.alert('exported!');
                    }, function () {
                        window.alert('An error occurred');
                    });
                } else {
                    $scope.myPromise3 = userService.exportToEHR(form.id).then(function () {
                        window.alert('exported!');
                    }, function () {
                        window.alert('An error occurred');
                    });
                }
            }
        };
        $scope.exportToAdDir = function (form) {
            userService.exportToAdDir(form.id).then(function () {
                window.alert('exported!');
            }, function () {
                window.alert('An error occurred');
            });
        };
    };
    DashboardCtrl.$inject = ['$scope', 'user', '$location', '$routeParams', '$filter', '$localStorage', '$mdToast', '$document', 'userService', '$window', '$mdDialog', '$sce', 'helper', '$q', 'iceService'];
    return DashboardCtrl;
})
;

